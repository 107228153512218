<template>
  <div>
    <div class="batch-operations-container" v-if="login && address && chainId === supportChainId">
      <div class="header" :class="{disabled: isFiltering || action.loading}">
        <div class="radio-container">
          <div class="title">Protocol:</div>
          <input type="radio" id="i" value=".i" v-model="selectedSuffix">
          <label for="i">.i</label><br>
          <input type="radio" id="o" value=".o" v-model="selectedSuffix" disabled>
          <label for="o">.o</label><br>
        </div>
        <div class="radio-container">
          <div class="title">Length:</div>
          <input type="radio" id="four" value="4" v-model="stringLength">
          <label for="four">4</label><br>
          <input type="radio" id="five" value="5" v-model="stringLength">
          <label for="five">5</label><br>
          <input type="radio" id="six" value="6" v-model="stringLength">
          <label for="six">6</label><br>
        </div>
        <div class="radio-container">
          <div class="title">Amount:</div>
          <input type="radio" id="less" value="100" v-model="selectedAmount">
          <label for="less">100</label><br>
          <input type="radio" id="medium" value="200" v-model="selectedAmount">
          <label for="medium">200</label><br>
          <input type="radio" id="more" value="300" v-model="selectedAmount">
          <label for="more">300</label><br>
        </div>
        <div class="radio-container">
          <div class="title">Operation:</div>
          <input type="radio" id="reservation" value="reservation" v-model="action.type">
          <label for="reservation">reservation</label><br>
          <input type="radio" id="registration" value="registration" v-model="action.type">
          <label for="registration">registration</label><br>
        </div>
      </div>
      <div class="body-container" :class="{disabled: isFiltering || action.loading}">
        <textarea
          class="left-textarea"
          v-model="input.value"
          placeholder="Enter DID names separated by spaces"
          spellcheck="false"
        >
        </textarea>
        <a-icon class="close-icon" type="close" @click="clearInputValue"/>
        <textarea
          class="right-textarea"
          v-model="invalidInputValue"
          placeholder="Filtered illegal DID name"
          spellcheck="false"
          readonly
          >
        </textarea>
      </div>
      <div class="footer">
        <div class="input-container">
          <input
            class="input"
            v-model.trim="agent.fullName"
            placeholder="Enter the DID full name of the agent <Required>"
            spellcheck="false"
          >
        </div>
        <div class="operate-container">
          <div
            @click="generateUniqueStrings"
            :class="{disabled: disabledAllBtn}"
          >
            Random
          </div>
          <div
            @click="filterHandler"
            :class="{disabled: disabledAllBtn || names.length === 0}"
          >
            <a-icon
              class="loading-icon"
              type="loading"
              v-show="isFiltering && !action.loading"
            />
            Filter
          </div>
          <div
            v-if="action.receipt.transactionHash"
            @click="viewDetails"
          >
            View Details
          </div>
          <div v-else
            @click="operationHandler"
            :class="{disabled: disabledAllBtn || names.length === 0}"
          >
            <a-icon
              class="loading-icon"
              type="loading"
              v-show="action.loading"
            />
            Launch
          </div>
        </div>
      </div>
    </div>
    <div class="not-connected" v-else>
      <div>
        <a-icon class="line1" type="warning" :style="{color: '#FFA5A5', fontSize: '132px'}"></a-icon>
        <p v-if="address" class="text">Please switch the network to {{ supportChainName }}.</p>
        <p v-else class="text">Please connect wallet and switch the network to {{ supportChainName }}.</p>
      </div>
    </div>
    <a-modal
      v-model="showModal"
      title="Fee Details"
      @ok="() => handleOk()"
      @cancel="() => handleCancel()"
      ok-text="Confirm"
      cancel-text="Cancel"
      centered
      wrapClassName="custom-confirm"
    >
      <div class="dialog-container">

        <div class="fee-name">
          <div class="name">Reserves</div>
          <div class="name">Registers</div>
          <div class="name">Total expenses</div>
        </div>

        <div class="price">
          <div>{{ cost.reserves }}</div>
          <div>{{ cost.registers }}</div>
          <div>{{ cost.total }}</div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  errorHandler,
  formatEther,
  getTxDetails
} from '@/utils/func'
// const zeroAddr = process.env.VUE_APP_ZERO_ADDR
const zeroNode = process.env.VUE_APP_ZERO_NODE

export default {
  name: 'batchOperations',
  data () {
    return {
      isFiltering: false,
      showModal: false,
      invalidInputValue: '',
      maxHeight: 200, // 最大高度为200px
      stringLength: 4,
      selectedAmount: 100,
      selectedSuffix: '.i',
      selectedOperation: '',
      agent: {
        fullName: '',
        nodeHash: ''
      },
      input: {
        value: '',
        timer: null,
        isEditing: false
      },
      action: {
        type: '',
        receipt: {},
        loading: false
      },
      cost: {
        registers: '',
        reserves: '',
        total: '',
        totalWei: ''
      },
      names: []
    }
  },

  computed: {
    ...mapGetters(['login', 'address', 'chainId']),

    supportChainId () {
      return process.env.VUE_APP_SUPPORT_CHAIN_ID
    },

    supportChainName () {
      return process.env.VUE_APP_SUPPORT_CHAIN_NAME
    },

    disabledAllBtn () {
      return this.input.isEditing ||
      this.isFiltering ||
      this.action.loading
    }
  },

  watch: {
    'input.value': {
      handler (newVal, oldVal) {
        this.action.receipt = {}
        this.input.isEditing = true

        if (newVal) {
          if (this.input.timer) {
            clearTimeout(this.input.timer)
          }
          this.input.timer = setTimeout(() => {
            this.beforeFilter()
            this.input.isEditing = false
          }, 2500)
        } else {
          this.names.length = 0
          this.invalidInputValue = ''
          this.input.isEditing = false
        }
      },
      deep: true
      // immediate: true
    }
  },

  created () {
    if (!this.address) {
      if (this.$fromUrl === '/') {
        return
      }
      this.$message.info('Please connect to wallet first.')
    }
  },

  methods: {
    handleOk () {
      if (this.action.type === 'reservation') {
        this.batchReserve()
      } else if (this.action.type === 'registration') {
        this.batchRegister()
      }
    },

    handleCancel () {
      this.action.loading = false
      this.showModal = false
    },

    async filterHandler () {
      if (!this.action.type) {
        this.$message.info('Please select the operation type!')
        return
      }

      this.isFiltering = true
      let funcName
      const invalids = []

      if (this.action.type === 'reservation') {
        funcName = 'filterBUF'
      } else if (this.action.type === 'registration') {
        funcName = 'filterDID'
      }

      try {
        const args = [
          this.names,
          this.selectedSuffix.split('')[1]
        ]
        console.log(`${funcName} args:`, args)
        const filteredArr = await this.$contracts.queryFilter()[funcName](...args)
        console.log(`${funcName}:`, filteredArr)

        for (const ele of filteredArr) {
          if (ele) {
            const index = this.names.indexOf(ele)
            if (index > -1) {
              invalids.push(this.names.splice(index, 1))
            }
          }
        }

        this.invalidInputValue = invalids.join(' ')
        this.input.value = this.names.join(' ')
      } catch (err) {
        errorHandler(err)
      } finally {
        this.isFiltering = false
      }
    },

    async operationHandler () {
      if (!this.action.type) {
        this.$message.info('Please select the operation type!')
        return
      }

      if (!this.agent.fullName && this.action.type === 'registration') {
        this.$message.info('Please enter the DID full name of the agent <Required>')
        return
      }

      this.action.loading = true
      await this.filterHandler()
      const { state } = await this.launchCheck()

      if (state !== 0) {
        this.action.loading = false
      }

      if (state === 0) {
        this.showModal = true
      } else if (state === 1e9) {
        this.$message.error('Agent does not exist, Please check and try again.')
      } else if (state >= 2e9) {
        this.$message.error('Some DIDs have just been reserved or registered, Please try again.')
      }
    },

    generateUniqueStrings () {
      const set = new Set()
      while (set.size < this.selectedAmount) {
        set.add(this.generateRandomString())
      }

      const strings = Array.from(set)
      this.input.value = strings.join(' ')
      console.log('Random strings:', strings)
    },

    generateRandomString () {
      let result = ''
      const characters = 'abcdefghijklmnopqrstuvwxyz0123456789'

      for (let i = 0; i < this.stringLength; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length))
      }
      return result
    },

    beforeFilter () {
      // const regExp = new RegExp('^(?!-)[a-z0-9-]{4,32}(?<!-)$')
      const regExp = new RegExp('^[a-z0-9]{1}[a-z0-9-]{2,30}[a-z0-9]{1}$')
      const temps = this.input.value.split(' ').map((ele) => ele.trim())
      const uniqueTemps = [...new Set(temps)]

      console.log('uniquerTemps temps:', uniqueTemps, temps)
      this.names = uniqueTemps.filter((ele) => regExp.test(ele))
      const invalids = uniqueTemps.filter((ele) => !regExp.test(ele))
      this.input.value = this.names.join(' ')

      if (invalids.length > 0) {
        this.invalidInputValue = invalids.join(' ')
      }
    },

    batchReserve () {
      const args = [
        this.names,
        this.address
      ]

      console.log('batchReserve args:', args)
      this.$contracts.valueMining(true).batchReserve(
        ...args,
        {
          value: this.cost.totalWei
        }
      ).then(result => {
        this.showModal = false
        return result.wait()
      })
        .then(receipt => {
          this.action.receipt = receipt
          this.$message.success('Transaction executed successfully!')
        })
        .catch(err => {
          errorHandler(err)
        })
        .finally(() => {
          this.handleCancel()
        })
    },

    batchRegister () {
      const args = [
        this.agent.nodeHash,
        this.address,
        this.names
      ]

      console.log('batchRegister args:', args)
      this.$contracts.registrar(true).batchRegister(
        ...args,
        {
          value: this.cost.totalWei
        }
      ).then(result => {
        this.showModal = false
        return result.wait()
      })
        .then(receipt => {
          this.action.receipt = receipt
          this.$message.success('Transaction executed successfully!')
        })
        .catch(err => {
          errorHandler(err)
        })
        .finally(() => {
          this.handleCancel()
        })
    },

    async launchCheck () {
      let agentSuffix, agentName
      const iHash = process.env.VUE_APP_I_NODE
      const oHash = process.env.VUE_APP_O_NODE

      if (this.action.type === 'reservation') {
        // agent.i
        agentName = 'agent'
        agentSuffix = iHash
      } else {
        switch (this.agent.fullName.split('.')[1]) {
          case 'i':
            agentSuffix = iHash
            break
          case 'o':
            agentSuffix = oHash
            break
          default:
            this.action.loading = false
            this.$message.error('Agent DID error, unsupported domain suffix.')
            return {}
        }
        agentName = this.agent.fullName.split('.')[0]
      }

      const askerNode = zeroNode
      const reserves = this.action.type === 'reservation' ? this.names : []
      const registers = this.action.type === 'registration' ? this.names : []

      const args = [
        0,
        askerNode,
        agentSuffix,
        agentName,
        [[], []],
        registers,
        reserves,
        []
      ]

      console.log('launchCheck args: ', args)

      try {
        const res = await this.$contracts.multiCall().launchCheck(...args)
        console.log('launchCheck:', res)

        const state = res.state.toNumber()
        this.agent.nodeHash = res.agentNode
        this.cost.registers = res.costs[2].isZero() ? 0 : formatEther(res.costs[2])
        this.cost.reserves = res.costs[3].isZero() ? 0 : formatEther(res.costs[3])
        this.cost.totalWei = res.costs[0].add(res.costs[1]).add(res.costs[2]).add(res.costs[3])
        this.cost.total = this.cost.totalWei.isZero() ? 0 : formatEther(this.cost.totalWei)

        console.log('State:', state)
        return { state }
      } catch (err) {
        errorHandler(err)
      }
      return {}
    },

    clearInputValue () {
      this.input.value = ''
    },

    viewDetails () {
      getTxDetails(this.chainId, this.action.receipt.transactionHash)
    }
  }
}
</script>

<style lang="scss" scoped>

.dialog-container{
  display: flex;
  width: 100%;
  height: 120px;
  text-align: left;
  overflow-y: auto;
  .fee-name {
    color: #555;
    .name:last-child {
      margin-top: 12px;
    }
  }
  .price {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    color: red;
    div {
      &:last-child {
        margin-top: 12px;
      }
      &::after {
        content: 'MATIC';
        margin-left: 8px;
        font-style: italic;
      }
    }
  }
}
.batch-operations-container {
  min-height: 660px;
  padding: 24px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0 0 6px $boxShadowColor;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .radio-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-right: 32px;
      .title {
        color: $blueOth;
        font-size: 16px;
        font-weight: 500;
        margin-right: 12px;
      }

      label {
        font-size: 16px;
        padding: 0 8px 0 4px;
      }
      input {
        margin-left: 8px;
      }

      label, input {
        cursor: pointer;
        user-select: none;
      }

      input[type="radio"]:checked {
        accent-color: $blueOth;
      }
    }
  }
  .body-container {
    position: relative;
    margin: 32px 0;
    .close-icon {
      position: absolute;
      top: 0;
      right: 50%;
      font-size: 20px;
      padding: 16px;
      color: #666;
      cursor: pointer;
    }
    .left-textarea, .right-textarea {
      width: 50%;
      min-height: 420px;
      padding: 24px 48px 24px 24px;
      color: $blueOth;
      font-size: 16px;
      resize: none;
      scrollbar-width: none;
      outline: none;
      border-style: none;
      border: 1px solid #ccc;
      box-shadow: 0 0 6px $boxShadowColorOth;
      cursor: text;

      &::-webkit-scrollbar {
        display: none;
      }

      &:first-child:hover {
        border-color: #666;
      }

      &:last-child {
        border-left: none;
      }

      &::placeholder {
        color: #888;
      }
    }
  }
  .footer {
    .input-container {
      margin-bottom: 32px;
      .input {
        width: 350px;
        height: 24px;
        font-size: 16px;
        color: $blueOth;
        font-weight: 500;
        border-style: none;
        border-bottom: 1px solid #ddd;
        text-align: center;
        outline: none;

        &:hover {
          border-bottom: 1px solid #ccc;
        }

        &::placeholder {
          font-size: 14px;
          font-weight: 400;
          color: #888;
        }
      }
    }
    .operate-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-evenly;

      div {
        width: 100px;
        height: 32px;
        line-height: 32px;
        font-weight: 500;
        border-radius: 4px;
        background: $bgColor;
        cursor: pointer;
        user-select: none;
        .loading-icon {
          padding-right: 8px;
          font-size: 14px;
        }

        &:active {
          opacity: 0.6;
        }
      }
    }
  }
}
.not-connected {
  display: flex;
    width: auto;
    height: 660px;
    margin: 0;
    background: #fff;
    border-radius: 16px;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 6px $boxShadowColor;
    .text {
      margin: 0;
      padding: 0;
      font-weight: 500;
    }
  }

@media screen and (max-width: $mobileWidth) {
  .batch-operations-container {
    min-height: auto;
    margin: 0 12px;
    .header {
      flex-direction: column;
      .radio-container {
        margin-right: unset;
        margin-top: 10px;
      }
    }
    .body-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .close-icon {
        position: absolute;
        top: 0;
        right: 0;
      }
      .left-textarea, .right-textarea {
        width: 100%;
        min-height: 200px;
        border: 1px solid $boxShadowColor;

        &:last-child {
          border-left: 1px solid $boxShadowColor;
          border-top: unset;
        }
      }
    }
    .footer {
      .input-container {
        .input {
          width: 100%;

          &::placeholder {
            font-size: 12px;
            font-weight: 400;
            color: #666;
            opacity: 0.6;
          }
        }
      }
      .operate-container {
        div {
          margin: 16px;
        }
      }
    }
  }
  .not-connected {
    margin: 0 16px;
  }
}
</style>
