<template>
  <div class="wel-view">
    <section class="index-view w1200">
      <div class="left-block">
        <div class="main-title">
          <div>With<a href="https://diddao.gitbook.io/" target="_blank">DAOG</a> ID,</div>
          <!-- <div class="airdrop-btn" @click="showAirdropPage"> -->
          <div class="airdrop-btn" @click="goAtsPage">
            <!-- <div class="title">Airdrop</div> -->
            <div class="title">ArtsDAO Airdrop</div>
          </div>
        </div>

        <div class="main-describe">
          <div>communicate, cooperate and exchange in Web3.</div>
        </div>

        <!-- <div class="main-describe">
          <div>Own your identity in the digital world.</div>
          <div>Get started with a Web3 domain.</div>
        </div> -->

        <div class="search-bar">
          <a-input-search class="custom-input" placeholder="Input a DID name" size="large" @search="onSearch">
            <a-icon type="search" slot="prefix" class="search-icon"/>
            <a-dropdown slot="suffix" :trigger="['click','hover']" class="switch-suffix">
              <span class="selector">{{ activeSuffix }}<a-icon type="down" class="icon" /></span>
              <a-menu slot="overlay">
                <a-menu-item :key="index" v-for="(item, index) in domainSuffixes">
                  <div class="suffix" @click="handleSuffixChange(item)">{{ item }}</div>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
            <a-button class="search-btn" slot="enterButton" type="primary">
              Search with DAPP
            </a-button>
          </a-input-search>
        </div>

        <div class="domain-suffix-list">
          <div
            v-for="(item, index) in domainSuffixes"
            :key="index"
            class="domain-suffix-item"
            to="/dapp"
            >
            <router-link :to="`/dapp/${item}`">
              <div v-if="item === '.i'">
                <span class="suffix">{{ item }} </span>
                <span class="desc">[ for individuals ] enables people's data sovereignty.</span>
              </div>

              <div v-else>
                <span class="suffix">{{ item }} </span>
                <span class="desc">[ for organizations, DAO ] empowers producers to get incentives in a friendly way.</span>
              </div>
            </router-link>
          </div>
        </div>

      </div>
      <div class="right-block">
        <template>
          <a-carousel autoplay>
            <div v-for="item, index in imgs" :key="index">
              <img :src="item.img">
            </div>
          </a-carousel>
        </template>
      </div>

      <!-- <div class="css-rightBlock">
        <div class="css-cardImageTop-ref css-cardImage-cardImageTop">
          <span style="box-sizing:border-box;display:inline-block;overflow:hidden;width:initial;height:initial;background:none;opacity:1;border:0;margin:0;padding:0;position:relative;max-width:100%">
            <span style="box-sizing:border-box;display:block;width:initial;height:initial;background:none;opacity:1;border:0;margin:0;padding:0;max-width:100%">
              <img style="display:block;max-width:100%;width:initial;height:initial;background:none;opacity:1;border:0;margin:0;padding:0" alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27304%27%20height=%27388%27/%3e">
            </span>
            待替换图片1
            <img alt="nft-card-1" src="https://storage.googleapis.com/unstoppable-client-assets/images/landing/new/nft_pfp_card_1.webp" decoding="async" data-nimg="intrinsic" style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%">
          </span>
        </div>
        <div class="css-cardImageBottom-ref css-cardImage-cardImageBottom">
          <span style="box-sizing:border-box;display:inline-block;overflow:hidden;width:initial;height:initial;background:none;opacity:1;border:0;margin:0;padding:0;position:relative;max-width:100%">
            <span style="box-sizing:border-box;display:block;width:initial;height:initial;background:none;opacity:1;border:0;margin:0;padding:0;max-width:100%">
              <img style="display:block;max-width:100%;width:initial;height:initial;background:none;opacity:1;border:0;margin:0;padding:0" alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27304%27%20height=%27388%27/%3e">
            </span>
            待替换图片2
            <img alt="nft-card-2" src="https://storage.googleapis.com/unstoppable-client-assets/images/landing/new/nft_pfp_card_2.webp" decoding="async" data-nimg="intrinsic" style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%">
          </span>
        </div>
      </div> -->
    </section>

    <section class="arrow-container" v-if="showArrow">
      <div class="arrows" @click="viewDIDGuide">
        <svg>
            <path class="a1" d="M0 0 L30 32 L60 0"></path>
            <path class="a2" d="M0 20 L30 52 L60 20"></path>
            <path class="a3" d="M0 40 L30 72 L60 40"></path>
          </svg>
      </div>
    </section>

    <section class="did-guide" v-else>
      <div class="did-guide-container w1200">
        <div class="content-container">
          <div class="item" v-for="item, index in DIDGuides" :key="index">
            <div class="key">{{ item.text.split(':')[0] }}</div>
            <div class="value">{{ item.text.split(':')[1] }}</div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="partners">
      <div class="partners-container w1200">
        <div class="title">COOPERATIVE PARTNERS</div>
        <div class="items">
          <div class="item" v-for="item, index in partners" :key="index">
            <div class="logo">
              <img :src="item.img" alt=""/>
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <!-- <section class="count-view">
      <div class="count-container w1200">
        <div class="count-item">
          <div class="val">3,200,000+</div>
          <div class="description">Domains Registered</div>
        </div>
        <div class="count-item">
          <div class="val">307</div>
          <div class="description">Coins + Tokens Supported</div>
        </div>
        <div class="count-item">
          <div class="val">751</div>
          <div class="description">Integrations</div>
        </div>
        <div class="count-item">
          <div class="val">900</div>
          <div class="description">Partners</div>
        </div>
      </div>
    </section> -->
    <div v-if="address">
      <airdrop @visible="visibleHandler" :showDialog="showDialog"/>
    </div>
  </div>
</template>

<script>
// import share from '@/components/share.vue'
import airdrop from '@/components/airdrop.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'HomeArea',

  components: {
    airdrop
    // share
  },

  data () {
    return {
      showArrow: true,
      showDialog: false,
      alias: '',
      activeSuffix: '.i',
      domainSuffixes: [
        '.i',
        '.o'
      ],
      imgs: [
        { img: require('@/assets/wel/satoshi.i.png') },
        { img: require('@/assets/wel/bitcoin.o.png') },
        { img: require('@/assets/wel/alice.i.png') },
        { img: require('@/assets/wel/polygon.o.png') },
        { img: require('@/assets/wel/bob.i.png') },
        { img: require('@/assets/wel/ethereum.o.png') }
      ],

      DIDGuides: [
        { text: 'Agent: If you have a DAOG ID, you also become a co-builder of the DAOG ID project, you have the right to sell DAOG IDs and get the corresponding rewards.' },
        { text: 'Mining: You can mine and help discover high-value DAOG IDs by liking them, and when they are purchased, you will get a fixed amount of rewards.' },
        { text: 'Resale: In DAOG ID smart contracts, there is a built-in decentralized exchange on which you can place DAOG ID sell orders, buyers can use the DAPP engine to find them.' },
        { text: "Reservation: By pre-ordering, you can somewhat prevent other buyers from competing for the same ID at a lower price, or profit from other buyers' higher priced purchases." }
      ]

      // partners: [
      //   { name: 'Bool Network', img: require('@/assets/wel/logo_02.png') },
      //   { name: 'EagleMask Wallet', img: require('@/assets/wel/logo_02.png') },
      //   { name: 'Midgard', img: require('@/assets/wel/logo_02.png') },
      //   { name: 'Safff Wallet', img: require('@/assets/wel/logo_02.png') },
      //   { name: 'SBTAuth Wallet', img: require('@/assets/wel/logo_02.png') },
      //   { name: 'ArtsDAO', img: require('@/assets/wel/logo_02.png') },
      //   { name: 'BYDAO', img: require('@/assets/wel/logo_02.png') },
      //   { name: 'GptDAO', img: require('@/assets/wel/logo_02.png') }
      // ]
    }
  },

  computed: {
    ...mapGetters(['address', 'chainId', 'login']),

    supportChainId () {
      return process.env.VUE_APP_SUPPORT_CHAIN_ID === this.chainId
    },

    supportChainName () {
      return process.env.VUE_APP_SUPPORT_CHAIN_NAME
    }
  },

  methods: {
    onSearch (searchText) {
      this.$emit('showDIDInstructions', {
        isShow: false
      })

      this.$router.push({
        path: '/dapp',
        query: {
          name: searchText.trim() + this.activeSuffix
        }
      })
    },

    goAtsPage () {
      this.$router.push({
        path: '/arts/show-center'
      })
    },

    handleSuffixChange (suffix) {
      this.activeSuffix = suffix
    },

    showAirdropPage () {
      if (!this.address || !this.login) {
        this.$message.info('Please connect to wallet first.')
        return
      }
      if (!this.supportChainId) {
        this.$message.info(`Please switch to ${this.supportChainName} network`)
        return
      }
      this.showDialog = true
    },

    viewDIDGuide () {
      this.showArrow = false
      setTimeout(() => {
        document.documentElement.scrollTop += 480
      }, 0)
    },

    visibleHandler (val) {
      this.showDialog = val
    }
  }
}
</script>

<style lang="scss" scoped>
.ant-dropdown-menu-item {
  font-size: 18px !important;
}

/* 设置 Carousel 的宽度和高度 */
// ::v-deep .ant-carousel {
//   width: 100%;
//   height: 400px;
// }

/* 设置图片与底部按钮之间的外边距 */
::v-deep .ant-carousel .slick-dots li {
  margin-top: -120px;
  margin-left: 16px;
  &:first-child {
    margin-left: 0;
  }
}

/* 设置每个轮播项的 padding */
// ::v-deep .ant-carousel .slick-slide {
//   padding: 20px;
// }

// 设置按钮样式
::v-deep .ant-carousel .slick-dots li button {
  background-color: #999;
  &:hover {
    background-color: #333;
  }
}
::v-deep .ant-carousel .slick-dots li.slick-active button {
  background-color: $blueOth;
}

.wel-view{
  position: relative;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: -100px;
  padding-bottom: 24px;
}

.index-view{
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 740px;
  .main-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 40px;
    font-weight: 900;
    // font-family: fantasy;
    text-align: left;
    letter-spacing: 2px;
    margin-bottom: 24px;
    margin-top: -50px;

    a {
      padding-left: 14px;
      color: #2C3E50;
      transition: all 0.3s;

      &:hover {
        color: #fff;
        text-shadow: 0 0 6px $blueOth;
      }
    }
    .airdrop-btn {
      // width: 205px;
      // height: 64px;
      // line-height: 64px;

      // padding: 16px 32px;
      // font-size: 24px;
      // color: #fff;
      // text-align: center;
      // border-radius: 32px;
      // // background: linear-gradient(-90deg,#b443fa, $valueColor, #b443fa, #CF9AFA); /* 背景渐变 */
      // background: linear-gradient(90deg, #CF9AFA, #9D68FC,#C371FB); /* 背景渐变 */
      // background-size: 500% 500%; /* 背景大小 */
      // user-select: none;
      // cursor: pointer;
      // animation: gradientBG 2s ease infinite; /* 应用动画 */
      // /* 定义 keyframes 动画 */
      // @keyframes gradientBG {
      //   0% {
      //     background-position: 0% 50%;
      //   }
      //   50% {
      //     background-position: 100% 50%;
      //   }
      //   100% {
      //     background-position: 0% 50%;
      //   }
      // }
      color: $blueOth;
      font-size: 32px;
      font-style: italic;
      cursor: pointer;
      user-select: none;
      animation: blink 1s ease infinite;

      @keyframes blink {
        0% {
          color: #9D68FC;
          opacity: 1;
        }
        50% {
          color: #C371FB;
          opacity: 0.5;
        }
        100% {
          color: $blueOth;
          opacity: 1;
        }
      }

      &-container {
        display: flex;
        // align-items: center;
        // justify-content: space-between;
      }
    }
  }
  .main-describe{
    text-align: left;
    line-height: 1.5;
    font-size: 32px;
    font-weight: 900;
  }
}

.search-bar{
  margin: 48px auto 20px;
  box-shadow: 0 0 10px #d6d6dd;
  border-radius: 4px;
  .search-icon {
    font-size: 28px;
  }
  .selector {
    font-size: 24px;
  }

  .switch-suffix{
    display: inline-flex;
    width: 90px;
    height: 100%;
    padding: 0 16px;
    text-align: left;
    align-items: center;
    border-left: 1px solid #ddd;
    justify-content: space-between;
    background: #fff;
  }
}
.custom-input{
  $searchBarHeight: 64px;
  .search-btn {
    width: 200px;
  }
  ::v-deep .ant-input-wrapper{
    .ant-input:focus, .ant-input:hover{
      border-color: #d9d9d9;
      box-shadow: unset;
    }
    .ant-input-lg{
      height: $searchBarHeight;
      padding-left: 48px;
      font-size: 20px;
      border-color: #d9d9d9;
    }

    .ant-btn-lg{
      height: $searchBarHeight;
      padding: 0 24px;
    }

    .ant-btn-primary{
      background-color: $blueOth;
      border-color: $blueOth;
    }
  }
}

.domain-suffix-list{
  width: 100%;
  .domain-suffix-item{
    position: relative;
    text-align: left;
    .suffix {
      margin-right: 8px;
      font-size: 24px;
      font-weight: bold;
      color: $blueOth;
      font-family: "Courier New", Courier, monospace;
    }
    .desc {
      font-size: 16px;
    }

    a{
      color: #2c3e50;

      &:hover{
        color: $blueOth;
      }
    }
  }
}

.count-view{
  background: #fff;
}

.arrow-container {
  position: relative;
  height: 48px;
  .arrows {
    width: 60px;
    height: 72px;
    position: absolute;
    left: 50%;
    margin-left: -30px;
    bottom: 20px;

    path {
    stroke: #C371FB;
    fill: transparent;
    stroke-width: 1px;
    animation: arrow 2s infinite;
    -webkit-animation: arrow 2s infinite;

    @keyframes arrow {
      0% {opacity:0}
      40% {opacity:1}
      80% {opacity:0}
      100% {opacity:0}
    }
  }
    path.a1 {
      animation-delay: -1s;
      -webkit-animation-delay: -1s;
    }
    path.a2 {
      animation-delay:-0.5s;
      -webkit-animation-delay: -0.5s;
    }
    path.a3 {
      animation-delay:0s;
      -webkit-animation-delay: 0s;
    }
  }
}

.did-guide {
  width: 100%;
  margin-top: -120px;

  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      max-width: 600px;
      font-size: 32px;
      font-weight: bold;
    }
    .content-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      padding-top: 48px;
      padding-bottom: 60px;
      .item {
        width: 584px;
        height: 220px;
        padding: 16px;
        margin-right: 32px;
        margin-bottom: 32px;
        background: transparent;
        border-radius: 16px;
        border: 2px solid $bgColor;
        cursor: pointer;
        transition: all 0.3s;
        .key {
          margin-bottom: 32px;
          font-size: 24px;
          font-weight: bold;
          text-align: left;
          word-wrap: break-word;
          overflow-wrap: break-word
        }

        .value {
          font-size: 20px;
          text-align: left;
          word-wrap: break-word;
          overflow-wrap: break-word
        }

        &:hover {
          transform: translateY(-10px);
          box-shadow: 0 0 16px #ddd;
        }

        &:nth-child(3):hover, &:nth-child(4):hover {
          transform: translateY(10px);
          box-shadow: 0 0 16px #ddd;
        }

        &:nth-child(2n) {
          margin-right: 0px;
        }
      }
    }
  }
}

// .partners {
//   margin: 60px 0;
//   .partners-container {
//     .title {
//       margin-bottom: 32px;
//       font-size: 28px;
//       font-weight: 900;
//       text-align: left;
//     }
//     .items {
//       display: flex;
//       flex-wrap: wrap;
//       align-items: center;
//       justify-content: center;
//       .item {
//         padding: 32px;
//         .logo {
//           font-size: 20px;
//           font-weight: 600;
//           white-space: nowrap;

//           img {
//             width: 32px;
//             vertical-align: middle;
//             margin-right: 12px;
//           }
//         }
//       }
//     }
//   }
// }

.right-block {
  width: 100%;
  max-width: 450px;
  height: 100%;
  margin-top: -10px;
  text-align: center;

  img {
    width: 100%;
    pointer-events: none;
  }
}

.css-rightBlock {
  margin-top: -120px;
  position: relative;
  width: 100%;
  max-width: 400px;
  padding-top: 20px;
  z-index: 1;
  min-height: 408px;
  -webkit-transition: min-height,max-width,padding-top 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: min-height,max-width,padding-top 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.css-rightBlock:hover .css-cardImageBottom-ref {
    -webkit-transform: matrix(1, 0, 0, 1, 0, 0);
    -moz-transform: matrix(1, 0, 0, 1, 0, 0);
    -ms-transform: matrix(1, 0, 0, 1, 0, 0);
    transform: matrix(1, 0, 0, 1, 0, 0);
    right: 21%;
    bottom: 0;
    z-index: 0;
}

.css-rightBlock:hover .css-cardImageTop-ref {
    bottom: -21%;
    right: 10%;
    z-index: -1;
    -webkit-transform: matrix(0.99, 0.14, -0.14, 0.99, 0, 0);
    -moz-transform: matrix(0.99, 0.14, -0.14, 0.99, 0, 0);
    -ms-transform: matrix(0.99, 0.14, -0.14, 0.99, 0, 0);
    transform: matrix(0.99, 0.14, -0.14, 0.99, 0, 0);
}

.css-logoListContainer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.css-wnugkk-logoImage {
    margin-top: 16px;
}

.css-wnugkk-logoImage:not(:last-of-type) {
    margin-right: 28px;
}

.css-tldContainer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 12px;
}

.css-tldDivider {
    color: #DDDDDF;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.css-cardImage-cardImageTop {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 304px;
    border-radius: 16px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.07),0px 6px 6px rgba(0, 0, 0, 0.04),0px 12px 12px rgba(0, 0, 0, 0.03),0px 20px 24px rgba(0, 0, 0, 0.03),0px 32px 40px rgba(0, 0, 0, 0.02),0px 80px 100px rgba(0, 0, 0, 0.16),0px 0px 4px rgba(0, 0, 0, 0.08),0px 8px 48px rgba(0, 0, 0, 0.08);
    -webkit-transition: -webkit-transform 850ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,right 850ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,bottom 850ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,z-index 850ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: transform 850ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,right 850ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,bottom 850ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,z-index 850ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.css-cardImage-cardImageTop img {
    object-fit: cover;
}

.css-cardImage-cardImageTop.css-cardImage-cardImageTop {
    position: absolute;
    bottom: 0;
    right: 21%;
    z-index: 0;
}

.css-cardImage-cardImageBottom {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 304px;
    border-radius: 16px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.07),0px 6px 6px rgba(0, 0, 0, 0.04),0px 12px 12px rgba(0, 0, 0, 0.03),0px 20px 24px rgba(0, 0, 0, 0.03),0px 32px 40px rgba(0, 0, 0, 0.02),0px 80px 100px rgba(0, 0, 0, 0.16),0px 0px 4px rgba(0, 0, 0, 0.08),0px 8px 48px rgba(0, 0, 0, 0.08);
    -webkit-transition: -webkit-transform 850ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,right 850ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,bottom 850ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,z-index 850ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: transform 850ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,right 850ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,bottom 850ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,z-index 850ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.css-cardImage-cardImageBottom img {
    object-fit: cover;
}

.css-cardImage-cardImageBottom.css-cardImage-cardImageBottom {
    position: absolute;
    bottom: -21%;
    right: 10%;
    z-index: -1;
    -webkit-transform: matrix(0.99, 0.14, -0.14, 0.99, 0, 0);
    -moz-transform: matrix(0.99, 0.14, -0.14, 0.99, 0, 0);
    -ms-transform: matrix(0.99, 0.14, -0.14, 0.99, 0, 0);
    transform: matrix(0.99, 0.14, -0.14, 0.99, 0, 0);
}

.css-9dxhbv-subTitle {
    margin: 0;
    font-family: 'Inter',sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    word-break: break-word;
    font-family: inherit;
    font-size: 1.375rem;
    color: #62626A;
}

.css-root-searchBarContainer {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 8px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
    height: 72px;
    padding-left: 24px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.04),0px 2px 3px rgba(0, 0, 0, 0.07),0px 6px 6px rgba(0, 0, 0, 0.04),0px 12px 12px rgba(0, 0, 0, 0.03),0px 20px 24px rgba(0, 0, 0, 0.03);
    max-width: $mobileWidth;
    width: 100%;
}

// .count-container{
//   display: flex;
//   align-items: center;
//   height: 200px;

//   .count-item{
//     width: 50%;
//     padding: 40px 0;

//     .val{
//       font-size: 48px;
//       font-weight: bold;
//     }
//   }
// }

@media screen  and (max-width: $mobileWidth){
  .index-view{
    flex-direction: column-reverse;
    flex: unset;
    min-height: auto;

    .main-title{
      flex-direction: column;
      text-align: center;
      font-size: 32px;
      .airdrop-btn {
        font-size: 28px;
        margin-top: 28px;
      }
    }
    .main-describe{
      font-size: 28px;
      text-align: center;
    }
  }

  .search-bar {
    .search-icon {
      font-size: 24px;
    }
    .switch-suffix {
      width: 48px;
      padding-right: 0;
    }
  }

 .custom-input {
  .search-btn {
    width: 130px;
  }
  ::v-deep .ant-input-wrapper {
    .ant-input-lg {
      padding-left: 40px;
      font-size: 14px;
      border-color: #d9d9d9;
    }
    .ant-btn-lg {
      font-size: 14px !important;
      padding: 0;
    }
  }
}

  .left-block{
    width: 100%;
    padding: 0 16px 32px;
    margin-top: 60px;
  }

  .right-block {
    max-width: 280px;
    height: 100%;
    margin-top: 60px;

    ::v-deep .ant-carousel .slick-dots li {
      margin-top: -42px;
    }
  }
  .domain-suffix-list {
    width: calc(100% - 98px);
  }
  .arrow-container {
    margin-top: 48px;
  }
  .did-guide {
    margin-top: 48px;
    .did-guide-container {
      padding: 0 16px;
      .content-container {
        flex-direction: column;
        flex-wrap: nowrap;
        .item {
          margin-right: unset;
          width: auto;
          height: auto;
          // height: auto;
        }
      }
    }
  }

  .partners {
    .partners-container {
      .title {
        text-align: center;
      }
    }
  }

  // .left-block{
  //   width: 100%;
  //   padding: 0 16px 32px;
  //   margin-top: 90px;
  // }
  .css-rightBlock {
    max-width: 150px;
    min-height: 153px;
    margin-top: -16px;
  }

  .css-cardImage-cardImageTop{
    max-width: 120px;
  }

  .css-cardImage-cardImageBottom{
    max-width: 120px;
  }

  .count-container{
    flex-direction: column;
    align-items: center;
    height: auto;
    .count-item{
      width: 100%;
      padding: 32px 0;

      .val{
        font-size: 32px;
      }
    }
  }
}
</style>
