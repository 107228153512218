<template>
  <div class="my-domain" v-if="login && address && chainId === supportChainId">
    <div class="side-bar" :style="{'max-height': maxHeight}">
      <div v-if="!tip && sideBar.list.length === 0" class="tip">
        <a-icon class="loading-icon" type="loading-3-quarters"/>
        Loading...
      </div>

      <div v-else-if="tip" class="tip">
        {{ tip.split(',')[0] }}
      </div>

      <div v-else>
        <div class="did-nft">
          <div class="img" @click="changedNFTImg">
            <div class="text">Avatar Market</div>
          </div>
          <!-- <img
           src="@/assets/sale.gif"
           :style="{ 'border-radius': showCircle ? '50%': '8px' }"
           @click="changedNFTImg"
          /> -->
          <!-- <a-icon type="file-image" style="font-size: 60px; padding-top: 50px;"/> -->
        </div>
        <div
          v-for="(item, index) in sideBar.list"
          class="side-item"
          :class="{active: index === sideBar.active}"
          @click="chooseSideItem(index)"
          :key="index">
          {{ item.name.length > 13 ? item.name.slice(0, 13) + '...' : item.name }}
        </div>
      </div>
    </div>

      <div class="right-container" v-if="currentSideBarItem.name">
        <div class="content-body">

          <div class="info">
            <div class="row topbar" style="margin-bottom: 16px">
              <div class="tab-bar">
                <div
                  v-for="(item, index) in tabBar.list"
                  class="tab-item"
                  :class="{active: index === tabBar.active}"
                  @click="chooseTabItem(index)"
                  :key="index">
                  {{ item.name }}
                </div>
                  <span class="header-btn" @click="toExplorer">Explorer &gt;&gt;</span>
              </div>

              <template v-if="tabBar.active === 0">
                <share
                  v-if="showDialog"
                  :dataUrl="dataUrl"
                  :agentLink="agentLink"
                  :showDialog="showDialog"
                  @visible="visibleHandler"
                />
                <a-button class="agent-btn" @click="showSharePage">
                Share to Earn
              </a-button>
            </template>
          </div>

          <template v-if="tabBar.active === 1">
            <div class="row" style="height: 48px">
              <div class="domain-name">
                {{ DIDFullName }}
              </div>
              <a-icon class="copy-icon" type="copy" @click="copyDomainName" />
            </div>

            <template v-if="currentSideBarItem.isOrder">
              <div class="row" style="height: 48px">
                <div class="label bold f16">Taker:</div>
                <div class="content">
                  <div>{{ orderTaker }}</div>
                </div>
              </div>
              <div class="row" style="height: 56px">
                <div class="label bold f16">Current price</div>
                <div class="content">
                  <span class="price">
                    {{ currentPriceByEther }}
                    <span class="symbol">MATIC</span>
                    <!-- <img class="ethereum-icon" src="@/assets/registration/ethereum.svg"> -->
                    <img class="matic-token" src="@/assets/network/matic-token.png">
                  </span>
                </div>
              </div>
              <div class="row" style="height: 56px; ">
                <div class="label bold f16">Expiration time</div>
                <div class="content">
                  <div>{{ getUTCFormattedDate(currentSideBarItem.expire) }}</div>
                </div>
              </div>
            </template>

            <template v-if="!currentSideBarItem.isOrder || showSettingInput">
              <div class="row" style="height: 80px">
              <div class="label bold f16">Price</div>
              <div class="content">
                <a-tooltip>
                  <template slot="title">
                    <span>Selling price</span>
                  </template>
                  <a-icon type="exclamation-circle" />
                </a-tooltip>
              </div>
            </div>

            <div style="margin-bottom: 32px; position: relative;" >
              <a-input v-model.trim="input.value" size="large" :disabled="action.loading" placeholder="Enter the price you want to sell">
                <a-select slot="addonAfter" default-value="MATIC" style="width: 100px" dropdownClassName="blue-select">
                  <a-select-option value="matic">
                    MATIC
                  </a-select-option>
                </a-select>
              </a-input>
              <div class="input-error-tip bold"
                v-show="input.priceFormatError || input.allowMaxPriceError || input.decimalsError">
                <a-icon class="icon" type="close-circle" theme="filled" />
                <span v-show="input.priceFormatError">Wrong price format, please re-enter.</span>
                <span v-show="input.decimalsError">Precision cannot exceed 18 decimal places for price.</span>
                <span v-show="input.allowMaxPriceError">Can only be 10,000,000,000 Matic for max biding.</span>
              </div>
            </div>

            <div class="row" style="height: 80px;">
              <div class="label bold f16">Duration</div>
              <div class="content">
                <a-tooltip>
                  <template slot="title">
                    <span>Duration of sale</span>
                  </template>
                  <a-icon type="exclamation-circle" />
                </a-tooltip>
              </div>
            </div>

            <div style="margin-bottom: 32px; position: relative;">
              <a-input v-model.trim="durationInput.value" size="large" :disabled="action.loading" placeholder="Enter the duration quantity">
              <a-select slot="addonAfter" default-value="Days" style="width: 100px" size="large" @change="durationHandlerChange" dropdownClassName="blue-select">
                <a-select-option value="Days">
                  Days
                </a-select-option>
                <a-select-option value="Weeks">
                  Weeks
                </a-select-option>
                <a-select-option value="Months">
                  Months
                </a-select-option>
              </a-select>
            </a-input>
              <div class="input-error-tip bold"
                v-show="durationInput.formatError || durationInput.allowMaxIntError">
                <a-icon class="icon" type="close-circle" theme="filled" />
                <span v-show="durationInput.formatError">Invalid positive integer, please re-enter.</span>
                <span v-show="durationInput.allowMaxIntError">The maximum allowed positive integer is 1,000.</span>
              </div>
            </div>

            <div class="row" style="height: 80px;">
              <div class="label bold f16">Taker</div>
              <div class="content">
                <a-tooltip>
                  <template slot="title">
                    <span>Address of taker</span>
                  </template>
                  <a-icon type="exclamation-circle" />
                </a-tooltip>
              </div>
            </div>

            <div style="margin-bottom: 32px; position: relative;">
              <a-input v-model.trim="takerAddr" size="large" :disabled="action.loading" placeholder="Enter the taker address <Optional>">
              </a-input>
              <div class="input-error-tip bold" v-show="(takerAddr && !isAddress) || takerAsMaker">
                <a-icon class="icon" type="close-circle" theme="filled" />
                <span v-show="takerAddr && !isAddress">Invalid address, please check and try again.</span>
                <span v-show="takerAsMaker">Taker cannot be the owner of current DID, please re-enter.</span>
              </div>
            </div>

            <div class="options">
              <div class="option-content">
                <div class="row" style="height: 32px">
                  <div class="label bold">Fees</div>
                  <div class="content">
                    <a-tooltip>
                      <template slot="title">
                        Pay fees
                      </template>
                      <a-icon type="exclamation-circle"/>
                    </a-tooltip>
                  </div>
                </div>

                <div class="row" style="height: 32px">
                  <div class="label">Service Fee</div>
                  <div class="content">0.2%</div>
                </div>
              </div>
            </div>
            </template>

            <template v-if="!currentSideBarItem.isOrder">
              <div class="buy-btn-icon" >
                <a-button
                  v-if = "action.type === 'make'"
                  class="buy-btn"
                  @click="viewTxDetails"
                  type="primary"
                  icon="eye">
                  View Details
                </a-button>
                <a-button
                  class="buy-btn"
                  v-else
                  :class="{disabled: disabled}"
                  icon="credit-card"
                  type="primary"
                  @click="sellDID"
                >
                  Sell Now
                </a-button>
              </div>
            </template>

            <template v-else>
              <div class="a-button-tip" :style="{'margin-top': marginTop}">
                <a-button
                  v-if="action.type === 'cancel'"
                  class="cancel-btn"
                  @click="viewTxDetails"
                  type="primary"
                  icon="eye"
                >
                  View Details
                </a-button>
                <a-button
                  v-else
                  class="cancel-btn"
                  @click="cancelFixedPriceOrder"
                  :class="{disabled: action.loading}"
                  type="primary"
                >
                  Cancel Order
                </a-button>

                <template v-if="action.type !== 'cancel'">
                  <a-button
                  v-if="action.type === 'modify'"
                    class="modify-btn"
                    @click="viewTxDetails"
                    type="primary"
                    icon="eye"
                  >
                  View Details
                  </a-button>
                  <a-button
                    v-else
                    class="modify-btn"
                    :class="{disabled: disabled}"
                    @click="modifyFixedPriceOrder"
                    type="primary"
                  >
                    Modify Order
                  </a-button>
                </template>
              </div>
            </template>
          </template>

          <template v-else-if="tabBar.active === 1">
            <div class="row" style="height: 56px; margin-bottom: 0px">
              <div class="domain-name">
                {{ DIDFullName }}
              </div>
              <a-icon class="copy-icon" type="copy" @click="copyDomainName" />
            </div>
          </template>

          <div v-if="tabBar.active === 0">
            <DIDSettings :currentNavMenuItem="currentSideBarItem"></DIDSettings>
          </div>

          <!-- test -->
          <template v-if="tabBar.active === 2">
            <likeInfo class="likeInfo" :isShow="true" :currentNavMenuItem="currentSideBarItem"/>
          </template>

          <template v-if="tabBar.active === 3">
            <div class="not-Implemented">Not Yet Implemented.</div>
          </template>

        </div>

        <!-- right side -->
        <div class="product-info" v-show="tabBar.active === 1">
          <div class="thumb">
            <!-- <img src="@/assets/sale.gif"> -->
            <div class="text">DAOG ID AVATAR</div>
          </div>
        </div>
      </div>

      <div class="step-container" v-show="tabBar.active === 1">
        <div class="step-item" :class="{active: action.step > 0}">
          <div class="prefix">
            <div class="check" v-if="action.step > 1">
              <a-icon type="check" />
            </div>
            <a-spin v-if="action.step === 1"/>
          </div>
          <div>
            {{
              currentSideBarItem.isOrder ?
              !showSettingInput ? '1 Request to cancel' : '1 Request to modify' :
              '1 Request to sell'
            }}
          </div>
        </div>
        <a-icon type="right" />

        <div class="step-item" :class="{active: action.step > 1}">
          <div class="prefix">
            <div class="check" v-if="action.step > 2">
              <a-icon type="check" />
            </div>
            <a-spin v-if="action.step === 2"/>
          </div>
          <div>2 Wait 30 seconds</div>
        </div>
        <a-icon type="right" />
        <div class="step-item" :class="{active: action.step === 3}">
          <div class="prefix">
            <div class="check" v-if="action.step === 3">
              <a-icon type="check" />
            </div>
          </div>
          <div>
            {{
              currentSideBarItem.isOrder ?
              !showSettingInput ? '3 Canceled order' : '3 Modified order' :
              '3 Published Order'
            }}
          </div>
        </div>
      </div>
    </div>

    <div class="right-container" v-else-if="!tip">
      <div class="pre-loading">
        <a-spin tip="Loading..." size="large" style="color: #333"/>
      </div>
    </div>

    <div v-else class="right-container">
      <div class="windmill">
        <div class="windmill-container" v-if="tip === 'No Records Found'">
          <div class="tip-text">{{ tip }}.</div>
          <img class="image" src="@/assets/registration/windmill-2.png" @load="imgLoaded">
          <div class="image-text" v-show="showImgText" @click="goDidRegistrationPage">DAOG ID</div>
        </div>
        <div class="tip-text" v-else>⚠️ Query failed, please try again later.</div>
      </div>
    </div>

    <a-modal
      v-model="dialog.show"
      :title="changedNFT ? 'Not Yet Implemented' :' Order details'"
      @ok="() => handleOk()"
      @cancel="() => handleCancel()"
      ok-text="Confirm"
      cancel-text="Cancel"
      centered
      wrapClassName="custom-confirm"
    >
      <div class="dialog-NFT-image-container" v-if="changedNFT">
      <template>
        <a-carousel autoplay :speed="1" :autoplaySpeed="900" :dots="false" :slides-to-show="5" :slides-to-scroll="1">
          <div v-for="item, index in imgs" :key="index">
            <img :src="item.img">
          </div>
        </a-carousel>
      </template>
      </div>
      <div class="dialog-container" v-else>
        <div class="key">
          <div>Selling price:</div>
          <div>Expiration time:</div>
          <div>Taker address:</div>
        </div>
        <div class="value">
          <div>{{ priceByEther }}</div>
          <div>{{ getUTCFormattedDate(this.expirationSeconds) }}</div>
          <div >{{ showTaker }}</div>
        </div>
      </div>
    </a-modal>
  </div>

  <div class="not-connected" v-else>
    <div>
      <a-icon class="line1" type="warning" :style="{color: '#FFA5A5', fontSize: '132px'}"></a-icon>
      <p v-if="address" class="text">Please switch the network to {{ supportChainName }}.</p>
      <p v-else class="text">Please connect wallet and switch the network to {{ supportChainName }}.</p>
    </div>
  </div>
</template>

<script>
import DIDSettings from '@/components/DIDSettings.vue'
import likeInfo from '@/components/likeInfo.vue'
import share from '@/components/share.vue'
import {
  errorHandler,
  copy,
  formatEther,
  createQRCode,
  getTxDetails,
  getDIDNodeHash,
  getUTCFormattedDate
} from '@/utils/func'
import { utils } from 'ethers'
import { mapGetters } from 'vuex'
import { isAddress } from 'web3-utils'

// let EditorContract, MultiCallContract

export default {
  name: 'myDomain',

  components: {
    DIDSettings,
    likeInfo,
    share
  },

  data () {
    return {
      showDialog: false,
      showImgText: false,
      agentLink: '',
      dataUrl: '',
      changedNFT: false,
      tip: '',
      isSucceeded: false,
      isPreLoading: false,
      showSettingInput: false,
      receipt: {
        txHash: null
      },
      action: {
        type: '',
        step: 0,
        receipt: {},
        loading: false
      },
      input: {
        value: '',
        timer: null,
        decimalsError: false,
        priceFormatError: false,
        allowMaxPriceError: false
      },
      durationUnit: 'Days',
      durationInput: {
        value: '',
        formatError: false,
        allowMaxIntError: false
      },
      expirationSeconds: null,
      takerAddr: '',
      priceByWei: null,
      priceByEther: null,
      currentSideBarItem: {},
      sideBar: {
        active: 0,
        list: []
      },

      tabBar: {
        active: 0,
        list: [
          { name: 'Profile' },
          { name: 'Resale' },
          { name: 'Likees' },
          { name: 'Auction' }
        ]
      },

      imgs: [
        { img: require('@/assets/wel/satoshi.i.png') },
        { img: require('@/assets/wel/bitcoin.o.png') },
        { img: require('@/assets/wel/alice.i.png') },
        { img: require('@/assets/wel/polygon.o.png') },
        { img: require('@/assets/wel/bob.i.png') },
        { img: require('@/assets/wel/ethereum.o.png') },
        { img: require('@/assets/wel/satoshi.i.png') },
        { img: require('@/assets/wel/bitcoin.o.png') },
        { img: require('@/assets/wel/alice.i.png') },
        { img: require('@/assets/wel/polygon.o.png') },
        { img: require('@/assets/wel/bob.i.png') },
        { img: require('@/assets/wel/ethereum.o.png') }
      ],

      dialog: {
        show: false
      }
    }
  },

  computed: {
    ...mapGetters(['login', 'address', 'chainId']),

    supportChainId () {
      return process.env.VUE_APP_SUPPORT_CHAIN_ID
    },

    supportChainName () {
      return process.env.VUE_APP_SUPPORT_CHAIN_NAME
    },

    zeroAddr () {
      return process.env.VUE_APP_ZERO_ADDR
    },

    orderTaker () {
      return this.currentSideBarItem.taker === this.zeroAddr
        ? 'Not set' : this.currentSideBarItem.taker
    },

    showTaker () {
      return this.takerAddr
        ? this.takerAddr : 'Not set'
    },

    showCircle () {
      if (this.currentSideBarItem.suffix === '.o') {
        return true
      }
      return false
    },

    marginTop () {
      return this.showSettingInput ? '50px' : '350px'
    },

    maxHeight () {
      if (this.tabBar.active === 0) return '1080px'

      if (this.showSettingInput) return '1060px'

      return '920px'
    },

    DIDFullName () {
      return this.currentSideBarItem.name + this.currentSideBarItem.suffix
    },

    currentPriceByEther () {
      return this.currentSideBarItem.price
    },

    showPrice () {
      return !this.input.priceFormatError &&
      !this.input.allowMaxPriceError &&
      !this.input.decimalsError
    },

    takerAsMaker () {
      if (this.takerAddr) {
        if (this.takerAddr.toLocaleLowerCase() === this.address.toLocaleLowerCase()) {
          return true
        }
      }
      return false
    },

    isAddress () {
      if (!this.takerAddr) return true
      return isAddress(this.takerAddr)
    },

    disabled () {
      return this.input.priceFormatError ||
      this.input.allowMaxPriceError ||
      this.input.decimalsError ||
      this.durationInput.formatError ||
      this.durationInput.allowMaxIntError ||
      this.takerAsMaker ||
      !this.isAddress ||
      this.action.loading
    }
  },

  watch: {
    'input.value': {
      handler (newVal, oldVal) {
        if (this.input.timer) {
          clearTimeout(this.input.timer)
        }
        this.input.timer = setTimeout(() => {
          this.processInputValue(newVal)
        }, 100)
      }
    },

    'durationInput.value': {
      handler (newVal, oldVal) {
        this.processDurationInput(newVal)
      }
    },

    login: {
      handler (newVal, oldVal) {
        console.log('login:', newVal, oldVal)
        this.getSidebarList()
      }
    }
  },

  created () {
    this.getSidebarList()
  },

  methods: {
    toMyProduct () {
      this.$router.replace({
        name: 'MyProduct'
      })
    },

    showSharePage () {
      this.showDialog = true
    },

    goDidRegistrationPage () {
      this.$emit('changeTab', {
        active: 0
      })
      this.$router.push('/dapp')

      // const didUrl = `${process.env.VUE_APP_APP_URL}/did`
      // 当前窗口跳转
      // location.href = didUrl

      // 新窗口打开
      // window.open(didUrl)
    },

    toExplorer () {
      this.$router.push({ name: 'DID' })
    },

    chooseTabItem (index) {
      this.tabBar.active = index
    },

    async chooseSideItem (index) {
      if (this.tip) return

      this.sideBar.active = index
      this.currentSideBarItem = this.sideBar.list[index]
      this.agentLink = `${process.env.VUE_APP_APP_URL}/dapp?agent=${this.DIDFullName}`
      this.dataUrl = createQRCode(this.agentLink)

      if (this.tabBar.active === 1) {
        this.clearInputs()
        this.action.type = ''
        this.action.step = 0
        this.action.receipt = {}
        this.showSettingInput = false
        this.expirationSeconds = null
        this.searchOrder(this.currentSideBarItem)
      }
    },

    sellDID () {
      if (this.input.value === '0') {
        this.$message.info('Your selling price is 0, please re-enter.')
        return
      }

      if (!this.priceByWei) {
        this.$message.info('Please enter the price you want to sell.')
        return
      }

      if (!this.durationInput.value) {
        this.$message.info('Please enter the number of duration.')
        return
      }

      this.expirationSeconds = this.getExpirationSeconds(this.durationUnit, parseInt(this.durationInput.value))
      this.action.loading = true
      this.dialog.show = true
    },

    handleOk () {
      this.dialog.show = false
      this.makeFixedPriceOrder()
    },

    handleCancel () {
      this.action.loading = false
      setTimeout(() => {
        this.changedNFT = false
      }, 500)
    },

    // DID 挂单
    makeFixedPriceOrder () {
      this.action.step = 1
      const nodeHash = getDIDNodeHash(this.DIDFullName)

      const args = [
        nodeHash,
        this.takerAddr ? this.takerAddr : this.zeroAddr,
        this.expirationSeconds,
        this.priceByWei
      ]

      if (this.currentSideBarItem.isOrder) {
        console.log('modifyFixedPriceOrder args:', args)
      } else {
        console.log('makeFixedPriceOrder args:', args)
      }

      this.$contracts.editor(true).makeFixedPriceOrder(...args)
        .then(result => {
          this.action.step = 2
          return result.wait()
        })
        .then(receipt => {
          this.action.receipt = receipt
          this.action.step = 3
          if (this.currentSideBarItem.isOrder) {
            this.action.type = 'modify'
            this.$message.success('Order modified successfully !')
          } else {
            this.action.type = 'make'
            this.$message.success('Order published successfully !')
          }
          this.searchOrder(this.currentSideBarItem)
        })
        .catch(err => {
          this.action.step = 0
          errorHandler(err)
        })
        .finally(() => {
          this.action.loading = false
        })
    },

    modifyFixedPriceOrder () {
      if (!this.showSettingInput) {
        this.showSettingInput = true
        return
      }

      this.sellDID()
    },

    cancelFixedPriceOrder () {
      this.showSettingInput = false
      this.action.loading = true
      this.action.step = 1
      const nodeHash = getDIDNodeHash(this.DIDFullName)
      console.log('cancelFixedOrder args:', nodeHash, false)

      this.$contracts.editor(true).cancelFixedOrder(nodeHash, false)
        .then(result => {
          this.action.step = 2
          return result.wait()
        })
        .then(receipt => {
          this.action.receipt = receipt
          this.action.step = 3
          this.action.type = 'cancel'
          this.searchOrder(this.currentSideBarItem)
          this.$message.success('Order canceled successfully !')
        })
        .catch(err => {
          this.action.step = 0
          errorHandler(err)
        })
        .finally(() => {
          this.action.loading = false
        })
    },

    getExpirationSeconds (durationUnit, durationNum) {
      const startTimeSeconds = Math.round(Date.now() / 1000)
      const oneDaySeconds = 24 * 60 * 60

      switch (durationUnit) {
        case 'Days':
          return startTimeSeconds + durationNum * oneDaySeconds
        case 'Weeks':
          return startTimeSeconds + durationNum * 7 * oneDaySeconds
        case 'Months':
          return startTimeSeconds + durationNum * 30 * oneDaySeconds
      }
    },

    copyDomainName () {
      if (!this.currentSideBarItem.name) {
        return
      }
      const data = this.DIDFullName
      copy(data)
    },

    copyAgentLink () {
      copy(this.agentLink)
    },

    durationHandlerChange (val) {
      this.durationUnit = val
    },

    async getSidebarList () {
      this.tip = ''
      this.sideBar.list = []
      this.currentSideBarItem = {}

      if (!this.address) {
        if (this.$fromUrl === '/') {
          return
        }
        this.$message.info('Please connect to wallet first.')
        return
      }

      if (this.chainId !== this.supportChainId) {
        if (this.$fromUrl === '/') {
          return
        } else {
          this.$message.info(`Please switch the network to ${this.supportChainName}.`)
          return
        }
      }

      this.isPreLoading = true
      const args = [
        this.address,
        false
      ]

      console.log('searchNameList args:', args)

      this.$contracts.multiCall().searchNameList(...args)
        .then(res => {
          // 无DID
          if (res.names.length === 0) {
            this.tip = 'No Records Found'
            return
          }

          const iList = res.names.slice(0, res.iLength)
          const oList = res.names.slice(res.oStart)
          console.log(`iList: ${iList}\noList: ${oList}\nnames: ${res.names}`)

          // test
          // let count = 0
          // for (const item of iList) {
          //   iList.push(item)
          //   count++
          //   if (count === 100) {
          //     break
          //   }
          // }

          iList.forEach((ele, index) => {
            this.sideBar.list.push({ name: ele, suffix: '.i' })
          })

          if (oList.length !== 0) {
            oList.forEach((ele, index) => {
              this.sideBar.list.push({ name: ele, suffix: '.o' })
            })
          }

          this.getOrderInfo()

          console.log('searchNameList:', res)
        })
        .catch(err => {
          // RPC网络错误
          this.tip = '⚠️ Query failed, please try again later.'
          errorHandler(err)
        })
        .finally(() => {
          this.isPreLoading = false
        })
    },

    searchOrder (item, index) {
      const functionSignature = 'searchOrder(bytes32,bool)'
      const nodeHash = getDIDNodeHash(item.name + item.suffix)

      this.$contracts.multiCall()[functionSignature](nodeHash, false)
        .then(orderInfo => {
          if (orderInfo.expire.toNumber() > 0) {
            item.isOrder = true
          } else {
            item.isOrder = false
          }

          item.market = orderInfo.market
          item.taker = orderInfo.taker
          item.expire = orderInfo.expire.toNumber()
          item.price = orderInfo.price.isZero() ? 0 : formatEther(orderInfo.price)

          if (index === 0) {
            this.chooseSideItem(index)
          }

          console.log(`${item.name + item.suffix} orderInfo: ${orderInfo}`)
        }).catch(err => {
          console.log(err)
        })
    },

    changedNFTImg () {
      this.dialog.show = true
      this.changedNFT = true
    },

    async getOrderInfo () {
      this.sideBar.list.forEach((item, index) => {
        this.searchOrder(item, index)
      })
    },

    getUTCFormattedDate (expirationSeconds) {
      return getUTCFormattedDate(expirationSeconds)
    },

    processInputValue (val) {
      try {
        this.input.priceFormatError = false
        this.input.allowMaxPriceError = false
        this.input.decimalsError = false
        if (!val) {
          this.priceByEther = 0
          return
        }

        const vals = val.split('.')
        const maxAllowPrice = 1e10

        if (vals.length === 1 && Number(vals[0]) > maxAllowPrice) {
          this.input.allowMaxPriceError = true
          return
        }

        if (vals.length === 2) {
          if (Number(vals[0]) >= maxAllowPrice) {
            this.input.allowMaxPriceError = true
            return
          }
        }

        this.priceByWei = utils.parseEther(val).toString()
        this.priceByEther = this.input.value

        console.log('Order price:', this.priceByWei)
      } catch (err) {
        switch (err.code) {
          case 'INVALID_ARGUMENT':
            this.input.priceFormatError = true
            break
          case 'NUMERIC_FAULT':
            this.input.decimalsError = true
            break
          default :
            this.input.priceFormatError = true
            console.log(err.code)
            break
        }
      }
    },

    processDurationInput (val) {
      this.durationInput.formatError = false
      this.durationInput.allowMaxIntError = false
      if (!val) return
      const regExp = /^[1-9]\d*$/g
      if (!regExp.test(val)) {
        this.durationInput.formatError = true
        return
      }
      const maxInt = 1e3
      if (parseInt(val) > maxInt) {
        this.durationInput.allowMaxIntError = true
      }
    },

    visibleHandler (val) {
      this.showDialog = val
    },

    imgLoaded () {
      this.showImgText = true
    },

    clearInputs () {
      this.takerAddr = ''
      this.input.value = ''
      this.durationInput.value = ''
    },

    getLocalTime (timestamp) {
      if (!timestamp) return '...'
      return new Date(timestamp * 1000).toString()
    },

    viewTxDetails () {
      getTxDetails(this.chainId, this.action.receipt.transactionHash)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .ant-modal-content {
    width: 550px;
  }
}

.dialog-NFT-image-container {
  height: 100px;
  img {
    width: 100px;
  }
}

.dialog-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  text-align: left;
  overflow-y: auto;
  .key {
    font-weight: 500;
    white-space: nowrap;
  }
  .value {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    div {
      color: $valueColor;
      font-weight: 300;
      white-space: nowrap;

       &:first-child {
        color: red;
        font-weight: 400;

        &::after {
          content: 'MATIC';
          margin-left: 8px;
          font-style: italic;
        }
      }
    }
  }
}
.not-Implemented {
  margin-top: 36px;
}
.not-connected {
  display: flex;
  width: auto;
  height: 660px;
  margin: 0;
  background: #fff;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: 0 0 6px $boxShadowColor;
  .text {
    margin: 0;
    padding: 0;
    font-weight: 500;
  }
}

.likeInfo {
  width: 100% !important;
}

.my-domain{
  display: flex;
  justify-content: space-between;
  text-align: left;

  // 自定义 ant 按钮样式
  ::v-deep .ant-btn-primary {
    background: $mainBtnColor;
    border: none;
  }

  .buy-btn-icon {
    // margin-top: 10px;
    .buy-btn {
      width: 180px;
      height: 42px;
      border-radius: 8px;
      font-size: 16px;

      &:hover {
        opacity: 0.7;
      }

      .tip-text {
        overflow: auto;
        overflow-wrap: break-word;
      }
    }

    .tip-icon {
      margin-left: 16px;
      color: #F5222D;
      font-size: 30px;
    }
  }

  ::v-deep .ant-input{
    &:hover{
      border-color: $blue;
    }
    &:focus{
      border-color: $blue;
      box-shadow: 0 0 0 2px $boxShadowColorOth;
    }
  }

  ::v-deep{
    .ant-input-group{
      .ant-select-selection{
        &:hover{
          border-color: transparent;
        }
        &:focus{
          border-color: transparent;
          box-shadow: none;
        }

        .ant-select-selection-selected-value {
          color: #555;
        }
      }

      .ant-select-open .ant-select-selection,
      .ant-select-focused .ant-select-selection{
        border-color: transparent;
        box-shadow: none;
      }

    }

    .input-error-tip {
      position: absolute;
      top: 42px;
      left: 3px;
      color: rgba($color: red, $alpha: 0.9);
      .icon {
        margin-right: 6px;
        padding-bottom: 2px;
        color: rgba($color: red, $alpha: 1);
        vertical-align: middle;
      }
    }
  }

  ::v-deep .ant-select-selection{
    &:hover{
      border-color: $blue;
    }
    &:focus{
      border-color: $blue;
      box-shadow: 0 0 0 2px $boxShadowColorOth;
    }
  }

  ::v-deep .ant-select-open .ant-select-selection,
  ::v-deep .ant-select-focused .ant-select-selection
  {
    border-color: $blue;
    box-shadow: 0 0 0 2px $boxShadowColorOth;
  }

}

.row{
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333333;

  .domain-name {
    font-size: 14px;
    color: $valueColor;
  }
  .label{
    // color: #666;
    // color: $blue;
  }

  .copy-icon {
    &:active {
      color: #b15be6;
    }
  }
}

.side-bar{
  // flex: 0.2;
  min-width: 220px;
  min-height: 660px;
  background: #fff;
  margin-right: 16px;
  padding: 0 16px 16px;
  border-radius: 16px;
  overflow-y: auto;
  box-shadow: 0 0 6px $boxShadowColor;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
  .tip {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 16px;
    margin-top: 16px;
    margin-bottom: 6px;
    color: $blue;
    font-size: 16px;
    line-height: 40px;
    border-radius: 8px;
    background: $bgColor;
  .loading-icon {
    font-size: 16px;
    animation: mymove 0.8s infinite;
    animation-timing-function: linear;
    animation-delay: -2s;
  }

    @keyframes mymove {
      from { transform: rotate(0deg); }
      to { transform: rotate(360deg) }
    }
  }

  .did-nft {
    position: sticky;
    top: 0;
    width: 100%;
    height: 200px;
    margin-bottom: 12px;
    padding-top: 24px;
    background-color: #fff;
    border-radius: none;
    text-align: center;
    z-index: 1;
    .img {
      .text {
        width: 150px;
        height: 140px;
        line-height: 140px;
        margin: 0 auto;
        font-size: 18px;
        font-weight: 900;
        color: #fff;
        text-shadow: 0 0 6px $blueOth;
        user-select: none;
        background: rgba(58, 78, 255, 0.05);
        border-radius: 16px;
        cursor: pointer;
        user-select: none;
        transition: all 0.3s;

        &:hover{
          transform: scale(1.15);
          background: $bgColor;
        }
      }
    }

    // img {
    //   width: 160px;
    //   height: 150px;
    //   padding-right: 12px;
    //   padding-top: 8px;
    //   // box-shadow: 0px 0px 10px #ccc;
    //   background: rgba(58, 78, 255, 0.05);
    //   border-radius: 16px;
    //   cursor: pointer;
    //   user-select: none;
    //   transition: all 0.3s;

    //   &:hover{
    //     transform: scale(1.15);
    //     background: $bgColor;
    //   }
    // }
  }

  .side-item{
    position: relative;
    padding: 0 16px;
    margin-bottom: 6px;
    font-size: 16px;
    line-height: 40px;
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
    overflow-x: hidden;
    text-overflow: ellipsis;
    &:hover {
      background: rgba(58, 78, 255, 0.06);
    }

    &.active{
      color: $blue;
      background: $bgColor;

      &::after{
        content: '';
        position: absolute;
        right: 16px;
        width: 4px;
        height: 4px;
        background: $blue;
        top: 0;
        bottom: 0;
        margin: auto;
        border-radius: 50%;
      }
    }
  }
}

.tab-bar {
  display: flex;
  color: #8791A5;

  .header-btn {
    line-height: 36px;
    font-size: 15px;
    font-weight: 700;
    color: $blue;
    cursor: pointer;
    user-select: none;
  }

  .tab-item{
    position: relative;
    margin-right: 32px;
    font-size: 14px;
    line-height: 36px;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;

    &.active{
      color: #14141E;
      font-weight: bold;

      &::after{
        content: '';
        position: absolute;
        height: 2px;
        background: $blue;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
}
.agent-btn {
  width: 142px;
  height: 32px;
  margin-right: 12px;
  color: #fff;
  text-align: center;
  line-height: 32px;
  border-radius: 8px;
  border-style: none;
  cursor: pointer;
  background: $mainBtnColor;

  &:hover {
    opacity: 0.7;
  }
}

.right-container {
  position: relative;
  flex: 1;
  min-height: 660px;
  padding: 18px 32px;
  padding-bottom: 24px;
  border-radius: 16px;
  background: #fff;
  overflow: hidden;
  overflow-y: auto;
  box-shadow: 0 0 6px $boxShadowColor;
  // firefox
  scrollbar-width: none;

  // chrome edge safari
  &::-webkit-scrollbar {
    display: none;
  }
.pre-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    img {
      width: 28px;
      height: 28px;
    }
  }
  .windmill {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 16px;
    .tip-text {
      color: $blue;
      white-space: nowrap;
    }
    .windmill-container {
      position: relative;
      width: 500px;
      text-align: center;
      .tip-text {
        padding: 32px 0;
      }
      .image {
        width: 100%;
        user-select: none;
        pointer-events: none;
        animation: mymove 80s infinite;
        animation-timing-function: linear;
        animation-delay: -2s;

        @keyframes mymove {
            from { transform: rotate(0deg) }
            to { transform: rotate(360deg) }
          }
        }
      .image-text {
        position: absolute;
        top: 252px;
        left: 211px;
        font-size: 18px;
        font-weight: 900;
        color: #fff;
        text-shadow: 0 0 6px $blueOth;
        cursor: pointer;
        user-select: none;
      }
    }
  }
  .step-tip {
    margin-top: 26px;
    font-size: 14px;

    p {
      color: $valueColor;
      line-height: 16px;
    }
  }
}
.content-body {
  display: flex;
  // justify-content: space-between;

  .info{
    flex: 1;
    width: 468px;
    overflow: hidden;
    .row{
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label{
        flex: 1;
        // color: #333;
        // color: $blue;
      }

      .content {
        margin-left: 12px;
        font-size: 14px;
        word-break: break-all;

        div {
          color: $valueColor;
        }
        .price {
          color: red;
          font-size: 18px;
          font-weight: bold;
          .symbol {
            font-style: italic;
          }
          .matic-token {
            width: 20px;
            margin-left: 10px;
            margin-bottom: 4px;
          }
          .ethereum-icon {
            width: 28px;
            margin-bottom: 6px;
            // padding-bottom: 5px;
            // vertical-align: middle;
          }
        }
    }
  }

    .a-button-tip {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .cancel-btn, .modify-btn {
        padding-top: 4px;
        width: 180px;
        height: 42px;
        border-radius: 8px;
        font-size: 16px;

        &:hover {
          opacity: 0.7;
        }
     }

    .cancel-tip {
      margin-top: 24px;
      color: $valueColor;
      font-size: 16px;

      .tip-text {
        margin-left: 8px;
      }
    }
    }
  }

  .options{
    margin-bottom: 24px;
    .bar{
      height: 32px;
      border-bottom: 1px solid #EDEEFF;

    }

    .option-content{
      padding: 16px 0;
    }
  }

  .product-info{

    .thumb{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 40px;
      width: 320px;
      height: 320px;
      border-radius: 16px;
      background: #fce9da;
      margin-bottom: 16px;
      background: rgba(58, 78, 255, 0.06);
      .text {
        font-size: 24px;
        font-weight: 900;
        color: #fff;
        text-shadow: 0 0 6px $blueOth;
        user-select: none;
        user-select: none;
        transition: all 0.3s;
      }
      // img {
      //   width: 200px;
      // }
    }
  }
}

.step-container{
  margin-top: 24px;
  width: 900px;
  height: 42px;
  border-radius: 8px;
  background: #F2F3FD;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;

  .step-item{
    width: 280px;
    padding: 0 32px;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    color: #999999;

    &.active{
      color: #333333;
    }

    .prefix{
      width: 26px;
      height: 26px;
      margin-right: 16px;
      display: inline-flex;
      align-items: center;
      line-height: 1;

      .check{
        width: 26px;
        height: 26px;
        padding-left: 6px;
        border-radius: 26px;
        line-height: 30px;
        color: $blue;
        background: rgba($color: $blue, $alpha: 0.3);
      }

      .ant-spin{
        line-height: 1;
      }
    }
  }
}

.primary{
  color: $blue;
}

.f16{
  font-size: 16px;
}

.f18{
  font-size: 18px;
}

.f24{
  font-size: 24px;
}

.bold {
  font-weight: 500;
}
.pointer{
  cursor: pointer;
}

.topbar{
  // border-bottom: 1px solid #ddd;
}

@media screen and (max-width: $mobileWidth){
  .not-connected {
    margin: 0 16px;
  }
  .my-domain{
    flex-direction: column;
    padding: 0 12px;

    ::v-deep .container{
      padding-right: 0;

      .data-item{
        margin-top: 16px;

        .data-title{
          margin-bottom: 16px;
        }
      }

      .data-content {
        margin-bottom: 28px;

        .data-desc{
          flex-direction: column;
          align-items: flex-start;
          white-space: normal;

          .key{
            width: 100%;
            font-weight: 400;
          }

          .amount{
            word-break: break-all;
            width: 100%;
          }
          .domain-name{
            margin-left: auto;
          }
        }
      }
    }
  }

  .right-container {
    .windmill {
      .windmill-container {
        .image-text {
          display: none;
        }
      }
    }
  }

  .content-body{
    flex-direction: column;
    .info {

      .row {
        .content {
          .price {
            font-size: 16px;
          }
        }
      }
      .a-button-tip {
        flex-direction: column;

        .modify-btn {
          margin-top: 24px;
        }
      }
    }
  }

  .side-bar{
    min-width: auto;
    min-height: auto;
    margin: 0 0 12px;

    .did-nft {
      margin: 0 auto 16px;
    }
  }

  .topbar{
    border-bottom: 1px solid #ddd;
  }

  .content-box {
    max-width: 274px;
  }

  .tab-bar{
    flex-wrap: wrap;
  }

  .right-container{
    min-height: 300px;
    margin: 0;
    padding: 16px;
  }

  .content-body{
    .info{
      width: 100%;
      margin-right: 0;
    }

    .product-info{
      padding-top: 24px;

      .thumb{
        width: 180px;
        height: 180px;
        margin: 0 auto;

        .text {
          font-size: 16px;
        }

        // img{
        //   width: 100px;
        // }
      }
    }
  }

  .buy-btn-icon{
    text-align: center;
  }

  .step-container{
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 16px 0;
    .step-item{

    }
    .anticon-right{
      transform: rotate(90deg);
      margin: 8px 0;
    }
  }
}
</style>
