<template>
  <div>
    <a-modal
      v-model="visible"
      @ok="() => handleOk()"
      @cancel="() => handleCancel()"
      ok-text="Confirm"
      cancel-text="Cancel"
      centered
      :footer="null"
      wrapClassName="custom-confirm"
    >
      <div class="dialog-container">
        <div class="header">
          <div class="logo">
            <img src="@/assets/wel/logo_02.png" alt="">
          </div>
          <div class="text">
            <div class="title">DAOG ID Airdrop</div>
            <!-- <div>$10000 worth of DAOG ID to 1000 lucky applicants</div> -->
          </div>
        </div>
        <div class="tab-bar">
          <div v-for="item, index in tabBar.list"
            :key="index"
            class="tab-item"
            :class="{active: index === tabBar.active}"
            @click="chooseTabItem(index)"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="infos" v-if="tabBar.active === 0">
          <div class="details-container">
            <div class="item">
            <div class="key">Amount</div>
            <div class="value">
              1 DAOG ID per applicant
            </div>
          </div>
          <div class="item">
            <div class="key">
              Airdrop registration starts
            </div>
            <div class="value">May 18, 08:00 +UTC</div>
          </div>
          <div class="item" v-if="getEndTime() === undefined">
            <div class="key">Not yet open</div>
            <div class="not-open">Coming soon ...</div>
          </div>
          <div class="item" v-else>
            <div class="key">Registration ends</div>
            <div class="not-open" v-if="getEndTime() === 0">
              Registration ended
            </div>
            <div class="ends-day" v-else>
              {{ getEndTime() }}
            </div>
          </div>
          </div>
          <div class="intro">
            <div class="title-btn" v-show="!isChecking">
              <div class="title" v-if="isApplied">
                The current address has already participated in this activity
              </div>
              <div class="title" v-else-if="reverseFullName">
                The current address already has one or more DAOG IDs.
              </div>
              <div class="title2" v-else>Apply now to get a free DAOG ID</div>
              <div class="btn" v-if="showApplyBtn" @click="applyAirdrop" :class="{disabled: disabledApplyBtn}">
                <a-icon class="loading-icon" v-show="apply.loading" type="loading"/>
                Apply
              </div>
            </div>
            <div class="text">
              Have a DAOG ID, enabling you to like premium domain names (i.e., mining) and receive a 90% bonus on the base registration fee as a referral reward when recommending new registrations. The process is as follows:<br>
              <br>
              1. Click the [Apply] button to submit your application to the airdrop smart contract.<br>
              2. Follow us on Twitter or join our Discord community.<br>
              3. Check for the DAOG ID airdropped to you.<br>
              4. Search for the domain name associated with your DAOG ID, add it to your shopping cart, and click to confirm the transaction on the blockchain.<br>
              <br>
              <!-- <a href="https://diddao.gitbook.io/docs" target="_blank">LEARN MORE</a> -->
            </div>
          </div>
          <div class="links">
            <div class="item">
              <i class="iconfont icon-Twitter"></i>
              <a href="https://twitter.com/DID_DAO" title="Twitter" target="_blank">
                https://twitter.com/DID_DAO
              </a>
              <a-icon class="copy-icon" type="copy" @click="copyLink('twitter')"/>
            </div>
            <div class="item">
              <i class="iconfont icon-Discord"></i>
              <a href="https://discord.gg/a3CJ6EhZkz" title="Discord" target="_blank">
                https://discord.gg/a3CJ6EhZkz
              </a>
              <a-icon class="copy-icon" type="copy" @click="copyLink"/>
            </div>
          </div>
        </div>
        <div class="infos" v-else>
          <div class="text">Wallet Address</div>
          <div class="addr-container" v-if="tip">
            <div class="tip-text">{{ tip }}</div>
          </div>
          <div class="addr-container" v-else-if="isQuerying">
            <a-icon class="loading-icon" type="loading-3-quarters"/>
          </div>
          <div class="addr-container" v-else>
            <div class="item" v-for="item in applicants" :key="item.addr">
              <div class="left">
                <div v-if="item.avatarUrl">
                  <img class="avatar" :src="item.avatarUrl" alt="">
                </div>
                <div v-else>
                  <a-icon type="credit-card"/>
                </div>
                <div class="addr">{{ item.addr }}</div>
              </div>
              <div class="did-name" v-if="item.name">
                {{ item.name + '.i'}}
              </div>
              <div class="status-text" v-else>
                Applied
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { utils } from 'ethers'
import { mapGetters } from 'vuex'
import { copy, errorHandler } from '@/utils/func'

export default {
  name: 'airdrop',
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      tip: '',
      isQuerying: false,
      isChecking: false,
      isApplied: false,
      tabBar: {
        active: 0,
        list: [
          { name: 'Details' },
          { name: 'Applicants List' }
        ]
      },

      apply: {
        loading: false,
        recept: {}
      },
      applicants: [],
      startMs: Date.parse('2023-05-18T08:00:00Z'),
      endMs: Date.parse('2023-05-28T08:00:00Z')
    }
  },

  computed: {
    ...mapGetters(['address', 'reverseFullName']),

    visible: {
      get () {
        return this.showDialog
      },

      set (val) {
        this.$emit('visible', val)
      }
    },

    showApplyBtn () {
      return !this.isApplied && !this.reverseFullName
    },

    disabledApplyBtn () {
      return !this.getEndTime() || this.apply.loading
    }
  },

  created () {
    this.checkApplied()
    this.getEndTime()
  },

  methods: {
    chooseTabItem (index) {
      this.apply.loading = false
      this.tabBar.active = index
      if (this.tabBar.active === 1) this.getApplicants()
    },

    async applyAirdrop () {
      if (this.startMs > Date.now() || this.endMs < Date.now()) return

      this.apply.loading = true

      try {
        await this.$contracts.airdrop(true).applyForAirdrop()
        this.isApplied = true
        this.$message.success('🎉 Successfully applied for airdrop!')
      } catch (err) {
        errorHandler(err)
      } finally {
        this.apply.loading = false
      }
    },

    async checkApplied () {
      this.isChecking = true

      try {
        const timestamp = await this.$contracts.airdrop().applied(this.address)
        console.log('applied:', timestamp)

        if (timestamp.toNumber() !== 0) this.isApplied = true
      } catch (err) {
        console.log(err)
      } finally {
        this.isChecking = false
      }
    },

    getEndTime () {
      const nowMs = Date.now()

      if (nowMs < this.startMs) {
        return undefined
      }

      if (nowMs >= this.endMs) {
        return 0
      }

      const oneMinuteMs = 60 * 1000
      const oneHoursMs = 60 * oneMinuteMs
      const oneDayMs = 24 * oneHoursMs

      const diffMs = this.endMs - nowMs
      const days = Math.round(diffMs / oneDayMs)

      if (days === 0) {
        const hours = Math.round((diffMs % (oneDayMs)) / (oneHoursMs))

        if (hours !== 0) return `Ends in ${hours} hrs`

        const minutes = Math.round(((diffMs % (oneDayMs)) % (oneHoursMs)) / oneMinuteMs)

        if (minutes !== 0) return `Ends in ${minutes} mins`

        return 0
      }

      return `Ends in ${days} days`
    },

    async getApplicants () {
      this.isQuerying = true
      this.applicants = []

      const airdropFunc = this.$contracts.airdrop()
      try {
        const len = await airdropFunc.length()
        if (len.toNumber() === 0) {
          this.tip = 'No Records Found.'
        } else {
          const { addrs, names } = await airdropFunc.slice(0, len - 1)
          console.log('slice:', addrs, names)

          if (this.isApplied && addrs.length > 1) {
            const index = addrs.indexOf(utils.getAddress(this.address))
            addrs.unshift(addrs.splice(index, 1))
            names.unshift(names.splice(index, 1))
          }

          const temps = []

          for (let i = 0; i < addrs.length; i++) {
            temps.push(
              {
                avatarUrl: `https://api-avatars.dappradar.com/${addrs[i]}.svg`,
                addr: addrs[i],
                name: names[i]
              }
            )
          }
          this.applicants = [...temps]
        }
      } catch (err) {
        this.tip = 'Query failed, please try again later.'
        console.log(err)
      } finally {
        this.isQuerying = false
      }
    },

    copyLink (flagStr) {
      let link
      if (flagStr) {
        link = 'https://twitter.com/DID_DAO'
      } else {
        link = 'https://discord.gg/a3CJ6EhZkz'
      }
      copy(link)
    },

    handleOk () {
      console.log('ok')
    },

    handleCancel () {
    }
  }
}

</script>

<style lang="scss" scoped>
@import url('~@/scss/iconfont.scss');
::v-deep .ant-modal {
    width: 680px !important;
}
::v-deep .ant-modal-content {
  width: 680px;
  border-radius: 16px;
}
.dialog-container {
  width: 100%;
  min-height: 380px;
  padding: 10px;
  .header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .logo {
      margin-right: 32px;

      img {
        width: 42px;
        user-select: none;
      }
    }
    .text {
      .title {
        font-size: 24px;
        font-weight: bold;
        color: $blueOth;
      }
    }
  }

  .tab-bar {
    display: flex;
    justify-content: flex-start;
    margin: 22px 0;
    color: #aaa;
    .tab-item {
      position: relative;
      margin-right: 32px;
      font-size: 14px;
      line-height: 36px;
      white-space: nowrap;
      cursor: pointer;
      user-select: none;

      &.active {
        font-size: 16px;
        color: #14141E;
        font-weight: bold;

        &::after {
          content: '';
          position: absolute;
          height: 2px;
          background: $blue;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }
  }
  .infos {
    display: flex;
    flex-direction: column;
    .details-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      border-radius: 8px;
      background-color: $bgColor;
      .item {
        display: flex;
        flex-direction: column;
        .key {
          font-size: 12px;
          margin-bottom: 4px;
        }
        .value, .ends-day, .not-open {
          font-size: 16px;
          font-weight: bold;
          color: #14141E;
        }
        .not-open {
          color: $valueColor;
        }
        .ends-day::before {
          display: inline-block;
          content: '';
          width: 8px;
          height: 8px;
          margin-right: 4px;
          vertical-align: middle;
          background: rgba($color: red, $alpha: 0.5);
          border-radius: 50%;
          animation: bloom 1.2s ease infinite; /* 应用动画 */
          animation-delay: -2s;

          /* 定义 keyframes 动画 */
          @keyframes bloom {
            0% {
              box-shadow: 0 0 4px rgba($color: red, $alpha: 1)
            }
            100% {
              background: red;
              box-shadow: 0 0 16px rgba($color: red, $alpha: .5);
            }
          }
        }
      }
    }
    .intro {
      margin-top: 32px;
      .title-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title, .title2 {
          font-size: 16px;
          font-weight: bold;
          color: #14141E;
        }
        .title {
          color: $valueColor;
          &::before {
            content: '⚠️'
          }
        }
        .btn {
          padding: 8px 32px;
          font-size: 18px;
          color: #fff;
          font-weight: bold;
          text-align: center;
          border-radius: 32px;
          background: linear-gradient(-90deg,#b443fa, #F7931E, #CF9AFA); /* 背景渐变 */
          background-color: #CF9AFA;
          cursor: pointer;
          user-select: none;
          transition: all 0.3s;

          &:hover {
            opacity: 0.8;
          }
          .loading-icon {
            margin-right: 16px;
            color: #333;
          }
        }
      }
      .text {
        margin-top: 16px;
          a {
            color: $blue;
          }
        }
      }

    .links {
      display: flex;
      justify-content: space-between;
      .item {
        display: flex;
        align-items: center;
        .iconfont {
          font-size: 21px;
          color: $blueOth;
        }

        a {
          margin: 0 12px;
          color: #595959;
          &:hover {
            color: $blue;
          }
        }
        .copy-icon {
          color: #000;
          cursor: pointer;
          &:active {
            color: #b15be6;
          }
        }
      }
    }
    .text {
      margin-bottom: 16px;
    }
    .addr-container {
      height: 500px;
      padding: 16px;
      text-align: center;
      overflow: auto;
      .tip-text {
        color: $blue;
      }
      .loading-icon {
        font-size: 24px;
        color: $blue;
        animation: loading 0.8s infinite;
        animation-timing-function: linear;
        animation-delay: -2s;
      }

      @keyframes loading {
        from { transform: rotate(0deg) }
        to { transform: rotate(360deg) }
      }
      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:not(:first-child) {
          margin-top: 16px;
        }
        .left {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .avatar {
            width: 24px;
            height: 24px;
            background-color: #ccc;
            border-radius: 3px;
            background: $bgColor;
          }
          .addr {
            margin-left: 16px;
            color: $blueOth;
          }
        }
        .status-text, .did-name {
          min-width: 96px;
          max-width: 154px;
          height: 32px;
          line-height: 32px;
          color: $blueOth;
          font-weight: 500;
          border-radius: 4px;
          overflow-x: auto;
          background: $bgColor;
        }
        .did-name {
          color: $valueColor;
        }
      }
    }
  }
}

@media screen and (max-width: $mobileWidth){
  .infos {
    .details-container {
      flex-direction: column;
      align-items: flex-start !important;
      .item {
        margin-top: 12px;
      }
    }
    .intro {
      .title-btn {
        flex-direction: column;
        .btn {
          margin: 32px 0;
        }
      }
    }
    .links {
      flex-direction: column;
    }
    .addr-container {
      .item {
        width: 400px;
        flex-direction: column;
        align-items: flex-start !important;
        .status-text, .did-name {
          margin-top: 8px;
        }
      }
    }
  }
}
</style>
