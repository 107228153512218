<template>
  <div v-if="showPage">
    <div class="operate-container" v-if="isShow && totalPages > 0">
      <div class="jump-select-search">
        <div class="left">
          <div class="jump-input">
            <span class="btn" :class="{disabled: disabledJumpBtn}" @click="queryByPageNum">Go to</span>
            <input class="page-input" v-model.trim="pageNum" @keyup.enter="queryByPageNum" type="number" min="1"/>
          </div>
          <div class="select-box">
            <a-select
              class="a-select"
              dropdownClassName="blue-select"
              default-value="all"
              @change="DIDTypeHandleChange"
            >
              <a-select-option value="all">
                All
              </a-select-option>
              <a-select-option value="i">
                i
              </a-select-option>
              <a-select-option value="o">
                o
              </a-select-option>
            </a-select>
            <a-select
              class="a-select"
              dropdownClassName="blue-select"
              default-value="asc"
              @change="sortRuleHandleChange"
            >
              <a-select-option class="option" value="asc">
                Asc
              </a-select-option>
              <a-select-option class="option" value="desc">
                Desc
              </a-select-option>
            </a-select>
          </div>
        </div>

        <div class="search-box">
          <a-input-search
            v-model.trim="inputValue"
            placeholder="Search"
            enter-button="Search"
            size="large"
            @search="onSearch"
          >
            <a-icon class="search-icon" slot="prefix" type="search"/>
          </a-input-search>
        </div>
      </div>

      <div class="page-turning">
        <span class="total-pages"> {{ totalPages }}</span>
        <div class="arrow-btn">
          <a-tooltip :class="{disabled: disabledLeftArrow}">
            <template slot="title">
              Previous page
            </template>
            <a-icon class="icon" @click="previousPage" type="left"/>
          </a-tooltip>
          <span class="current-page">{{ currentPages }}</span>
          <a-tooltip :class="{disabled: disabledReftArrow}">
            <template slot="title">
              Next page
            </template>
            <a-icon class="icon" @click="nextPage" type="right"/>
          </a-tooltip>
        </div>
      </div>
    </div>

    <div class="info">
      <div class="info-container"  v-if="infos.length > 0">
        <div class="info-item" v-for="item, index in infos" :key="index">
          <div class="info-row">
            <div class="left-container">
              <div class="left">
                <div class="label">{{ item.nameKey }}</div>
                <div class="value" :style="changColor(item)">
                  {{ item.fullName }}
                </div>
              </div>
              <div class="left">
                <div class="label">{{ item.timestampKey }}</div>
                <div class="value" :style="changColor(item)">
                  {{ item.date }}
                </div>
              </div>
            </div>
            <div class="right-container">
              <div class="right">
                <div class="label">{{ item.ownerKey }}</div>
                <div class="value" :style="changColor(item)">
                  {{ item.owner }}
                </div>
              </div>
              <div class="right">
                <div class="label">{{ item.hashKey }}</div>
                <div class="value" :style="changColor(item)">
                  {{ item.nodeHash }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="loading-text-icon" v-else>
        <a-icon v-if="isQuerying" class="loading-icon" type="loading-3-quarters"/>
        <div v-else class="text">{{ tip }}</div>
      </div>
    </div>
  </div>
  <div class="loading-text-icon" v-else>
    <a-icon v-if="isLoading" class="loading-icon" type="loading-3-quarters"/>
    <div v-else class="text">{{ tip }}</div>
  </div>
</template>

<script>
import { getUTCFormattedDate, getDIDNodeHash } from '@/utils/func'

// let MultiCallContract

export default {
  name: 'likeInfo',

  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    currentNavMenuItem: {
      type: Object,
      require: true
    }
  },

  data () {
    return {
      tip: '',
      pageNum: null,
      inputValue: '',
      showPage: false,
      isLoading: false,
      isQuerying: false,
      totalPages: 0,
      currentPages: 1,
      recordsPerPage: 100,
      infos: []
    }
  },

  computed: {

    disabledJumpBtn () {
      const regExp = /^[1-9]\d*$/g
      if (regExp.test(this.pageNum)) {
        return Number(this.pageNum) > this.totalPages
      } else {
        return true
      }
    },

    disabledLeftArrow () {
      return this.currentPages === 1 || this.isQuerying
    },

    disabledReftArrow () {
      return this.currentPages === this.totalPages || this.isQuerying
    },

    DIDNodeHash () {
      return getDIDNodeHash(this.currentNavMenuItem.name + this.currentNavMenuItem.suffix)
    },

    zeroAddr () {
      return process.env.VUE_APP_ZERO_ADDR
    }
  },

  watch: {
    currentNavMenuItem: {
      handler (newVal, oldVal) {
        if (newVal.name !== oldVal.name || newVal.suffix !== oldVal.suffix) {
          this.searchLikersOrLikees()
        }
      },
      deep: true
    }
  },

  created () {
    this.searchLikersOrLikees()
  },

  methods: {
    DIDTypeHandleChange () {
    },

    sortRuleHandleChange () {
    },

    changColor (item) {
      return { color: (item.owner !== this.zeroAddr) && this.isShow ? '#ccc' : '#FF9933' }
    },

    onSearch () {
    },

    queryByPageNum () {
      if (this.disabledJumpBtn) return

      const pageNum = Number(this.pageNum)
      if (this.currentPages === pageNum) return

      this.currentPages = pageNum
      this.searchLikees()
    },

    previousPage () {
      this.currentPages -= 1
      this.searchLikees()
    },

    nextPage () {
      this.currentPages += 1
      this.searchLikees()
    },

    async searchLikersOrLikees () {
      this.pageNum = null
      this.currentPages = 1
      this.totalPages = 0
      this.showPage = false
      this.isLoading = true

      try {
        this.isShow ? await this.searchLikees() : await this.searchLikers()
      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false
      }
    },

    async searchLikees () {
      this.tip = ''
      this.infos = []
      this.isQuerying = true

      const start = (this.currentPages - 1) * this.recordsPerPage
      const end = this.currentPages * this.recordsPerPage
      const args = [
        this.DIDNodeHash,
        start,
        end
      ]
      console.log('searchLikees args:', args)

      try {
        const res = await this.$contracts.multiCall().searchLikees(...args)
        console.log('searchLikees:', res)
        const { likees, owners, timestamps, fullNames } = res

        if (likees.length > 0) {
          const len = res[4].toNumber()
          this.totalPages = Math.ceil(len / (end - start))
          this.buildInfos(likees, owners, timestamps, fullNames)
        } else {
          this.tip = 'No Records Found.'
        }
      } catch (err) {
        this.tip = '⚠️ Query failed, please try again.'
        console.log(err)
      } finally {
        this.isQuerying = false
      }
    },

    async searchLikers () {
      this.infos = []
      console.log('searchLikers args:', this.DIDNodeHash)

      try {
        const res = await this.$contracts.multiCall().searchLikers(this.DIDNodeHash)
        const { likers, owners, timestamps, fullNames } = res

        console.log('searchLikers:', res)

        if (likers.length > 0) {
          this.buildInfos(likers, owners, timestamps, fullNames)
        } else {
          this.tip = 'No Records Found.'
        }
      } catch (err) {
        this.tip = '⚠️ Query failed, please try again.'
        console.log(err)
      }
    },

    buildInfos (arr, owners, timestamps, fullNames) {
      const tempArr = []
      const len = arr.length
      for (let i = 0; i < len; i++) {
        tempArr.push(
          {
            nameKey: 'Name',
            fullName: fullNames[i],
            ownerKey: 'Owner',
            owner: owners[i],
            timestampKey: 'Timestamp',
            date: getUTCFormattedDate(timestamps[i].toNumber()),
            hashKey: 'Hash',
            nodeHash: arr[i]
          })
      }
      this.infos = tempArr
      this.showPage = true
    }
  }
}
</script>

<style lang="scss" scoped>

.loading-text-icon {
  widows: 100%;
  text-align: center;
  .text {
    font-size: 18px;
    color: $blue;
  }
  .loading-icon {
    font-size: 24px;
    color: $blue;
    animation: mymove 0.8s infinite;
    animation-timing-function: linear;
    animation-delay: -2s;
  }

  @keyframes mymove {
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
  }
}

.operate-container {
  display: flex;
  flex-direction: column;
  margin: 24px 0;
  .jump-select-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      justify-content: space-between;
      .jump-input {
        margin-right: 24px;
        .btn {
          font-size: 16px;
          cursor: pointer;
          user-select: none;

          &:hover {
            color: $blue;
          }
        }
        .page-input {
          width: 64px;
          height: 32px;
          margin-left: 8px;
          text-align: center;
          border-radius: 4px;
          border: 1px solid #ddd;
          outline: unset;

          &:hover {
            border: 1px solid $blue;
          }
          &:focus {
            border: 1px solid $blue;
            outline: $boxShadowColorOth solid 2px;
          }
        }
      }
      .select-box {
        .a-select {
          margin-right: 24px;
        }
      }
    }
   .search-box {
    width: 500px;
      ::v-deep .ant-input-lg {
        padding-left: 40px;
      }
      .search-icon {
        font-size: 20px;
      }
    }
  }
  .page-turning {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
    font-size: 16px;
    .total-pages {
      color: $valueColor;
      margin-right: 32px;

      &::before {
        content: 'Total pages:';
        padding-right: 12px;
        color: #2c3e50;
      }
    }
    .arrow-btn {
      display: inline-block;
      padding-right: 4px;
      .icon {
        font-size: 16px;
        padding: 4px;
        cursor: pointer;

        &:hover {
          color: $blue;
          border-radius: 4px;
          background: $bgColor;
        }

        &:active {
          color: #b15be6;
        }
      }
      .current-page {
        color: $valueColor;
        padding: 0 12px;
        user-select: none;
      }
    }
  }
}
.info {
  width: 100%;
  .info-container {
    max-height: 680px;
    overflow-y: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
    .info-item {
      margin-top: 24px;

      &:first-child {
        margin-top: 16px;
      }

      &:not(:last-child)::after {
        content: "";
        display: block;
        height: 1px;
        background-color: rgba($color: #eee, $alpha: 0.6);
        margin-top: 24px;
      }

      .info-row {
        display: flex;
        .left-container {
          flex: 0.55;
          .left {
            display: flex;
            justify-content: space-between;
          }
        }
        .right-container {
          flex: 1;
          margin-left: 20px;
          .right {
            display: flex;
            justify-content: space-between;
          }
        }
        .label {
          font-weight: 500;
        }
        .value {
          color: $valueColor;
        }
      }
    }
  }
}

@media screen and (max-width: $mobileWidth) {
  .operate-container {
    align-items: center;
    .page-turning {
      margin-top: unset;
    }
    .jump-select-search {
      flex-direction: column;
      .left {
        .select-box {
          .a-select {
            &:last-child {
              margin-right: unset;
            }
          }
        }
      }
      .search-box {
        width: auto;
        margin: 24px 0;
      }
    }
  }
  .info {
    .info-container {
      .info-item {
        .info-row {
          flex-direction: column;
          .left, .right {
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            .value {
              word-break: break-all;
            }
          }
          .left:first-child {
            margin-top: 0;
          }
          .right-container {
            margin-left: 0;
          }
        }
      }
    }
  }
}

</style>
