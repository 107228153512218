<template>
    <div class="container">
      <div class="data-item">
        <div class="data-title">Node Info</div>
        <div class="data-content">
          <div class="data-desc">
            <span class="key">Full Name</span>
            <div class="value">
              {{ DIDFullName }}
              <a-icon class="copy-icon" @click="copyDomainName" type="copy"/>
            </div>
          </div>
        </div>
        <div class="data-content">
          <div class="data-desc">
            <div class="key">Name Hash</div>
            <div class="value">{{ DIDNodeHash }}</div>
          </div>
        </div>
        <div class="data-content">
          <div class="data-desc">
            <div class="key">Latest Transfer</div>
            <div class="value-btn">
              <div class="value">{{ latestTransfer }}</div>
              <a-button
                v-if="transfer.receipt.transactionHash"
                class="btn"
                @click="viewTxDetails()"
              >
                View Details
              </a-button>
              <a-button  v-else class="btn" :class="{disabled: transfer.loading}" @click="transferBufferDID">
                <a-icon class="loading-icon" v-show="transfer.loading" type="loading"/>
                Transfer
              </a-button>
            </div>
          </div>
        </div>
      </div>
      <div class="data-item">
        <div class="data-title">Likers</div>
        <likeInfo class="likeInfo" :currentNavMenuItem="currentNavMenuItem"/>
      </div>
      <a-modal
        v-model="showDialog"
        title="Enter recipient's address"
        @ok="() => handleOk()"
        @cancel="() => handleCancel()"
        ok-text="Confirm"
        cancel-text="Cancel"
        centered
        wrapClassName="custom-confirm"
      >
      <div class="dialog-container">
        <a-input class="recipient-input" v-model.trim="recipient"/>
      </div>
    </a-modal>
    </div>
</template>

<script>
import { isAddress } from 'web3-utils'
import { mapGetters, mapActions } from 'vuex'
import likeInfo from '@/components/likeInfo.vue'
import
{
  copy,
  getTxDetails,
  getDIDNodeHash,
  getUTCFormattedDate,
  errorHandler
} from '@/utils/func'

export default {
  name: 'derivativesInfo',
  props: {
    // 侧边选项卡当前激活的信息详情
    currentNavMenuItem: {
      type: Object,
      require: true
    }
  },

  components: {
    likeInfo
  },

  data () {
    return {
      showDialog: false,
      recipient: '',
      latestTransfer: '',
      transfer: {
        receipt: {},
        loading: false
      }
    }
  },

  computed: {
    ...mapGetters(['address', 'chainId']),

    DIDNodeHash () {
      return getDIDNodeHash(this.currentNavMenuItem.name + this.currentNavMenuItem.suffix)
    },

    DIDFullName () {
      return this.currentNavMenuItem.name + this.currentNavMenuItem.suffix
    },

    marginRight () {
      return 'margin-right: 166px;'
    }
  },

  watch: {
    currentNavMenuItem: {
      handler (newVal, oldVal) {
        if (newVal.name !== oldVal.name || newVal.suffix !== oldVal.suffix) {
          // this.latestTransfer = ''
          this.recipient = ''
          this.transfer.receipt = {}
          this.getLatestTransfer()
        }
      },
      deep: true
    }
  },

  mounted () {
    this.getLatestTransfer()
  },

  methods: {
    ...mapActions([
      'setAddress',
      'setChainId'
    ]),

    handleOk () {
      if (!this.recipient) {
        this.$message.info('Please enter the recipient\'s address.')
        return
      }

      if (this.recipient.toLocaleLowerCase() === this.address.toLocaleLowerCase()) {
        this.$message.info('Recipient cannot be the owner of current DID, please re-enter.')
        return
      }

      if (!isAddress(this.recipient)) {
        this.$message.info('Invalid address, please check and try again.')
        return
      }
      this.safeTransferFrom()
    },

    handleCancel () {
      this.transfer.loading = false
    },

    transferBufferDID () {
      this.showDialog = true
    },

    async getLatestTransfer () {
      console.log('getNodeRecord arg:', this.DIDNodeHash)
      try {
        const info = await this.$contracts.bufferDB().getNodeRecord(this.DIDNodeHash)
        this.latestTransfer = getUTCFormattedDate(info.transfer.toNumber())
        console.log('getNodeRecord:', info)
      } catch (err) {
        console.log(err)
      }
    },

    safeTransferFrom () {
      this.transfer.loading = true
      this.showDialog = false

      const args = [
        this.address,
        this.recipient,
        this.DIDNodeHash
      ]

      console.log('Transfer buffer DID args:', args)

      const functionSignature = 'safeTransferFrom(address,address,uint256)'

      this.$contracts.bufferDB(true)[functionSignature](...args)
        .then(result => {
          return result.wait()
        })
        .then(receipt => {
          this.transfer.receipt = receipt
          this.getLatestTransfer()
          this.$message.success('Successfully transfer!')
        })
        .catch(err => {
          errorHandler(err)
        })
        .finally(() => {
          this.transfer.loading = false
        })
    },

    viewTxDetails () {
      getTxDetails(this.chainId, this.transfer.receipt.transactionHash)
    },

    copyDomainName () {
      const data = this.DIDFullName
      copy(data)
    }
  }
}
</script>

<style lang="scss" scoped>
 ::v-deep .ant-input{
    &:hover{
      border-color: $blue;
    }
    &:focus{
      border-color: $blue;
      box-shadow: 0 0 0 2px $boxShadowColorOth;
    }
  }
.container {
  padding-right: 12px;
  .data-item {
    margin-top: 36px;
    border-bottom: 1px solid #eee;

    &:last-child {
      border-bottom: none;
    }
  }
  .likeInfo {
    width: 100%;
  }
  .data-title {
    text-align: left;
    color: $blue;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 24px;
  }
  .data-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 14px;

      .data-desc {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-size: 16px;
        font-weight: 500;
        white-space: nowrap;
      }
      .key {
        width: 45%;
      }
      .value-btn {
        display: flex;
        align-items: center;
      }

      .btn {
        width: 142px;
        height: 32px;
        margin-left: 24px;
        color: #fff;
        cursor: pointer;
        line-height: 32px;
        border-style: none;
        border-radius: 8px;
        text-align: center;
        background: $mainBtnColor;

        &:hover {
          opacity: 0.7;
        }
        .loading-icon {
          padding-right: 6px;
          font-size: 14px;
          font-weight: bold;
        }
      }
      .value {
        flex: 1;
        font-size: 14px;
        text-align: right;
        color: $valueColor;
        white-space: nowrap;
        .copy-icon {
          margin-left: 6px;
          font-size: 14px;
          cursor: pointer;
          color: #333;
          &:active {
            color: #b15be6;
          }
        }
      }
    }
  }

@media screen  and (max-width: $mobileWidth) {
  .container {
    .data-item {
      .data-content {
        .data-desc {
          .value-btn {
          }

          .value {
            width: 100%;
            text-align: left;
            white-space: normal;
            overflow-wrap: break-word;
            .text {
              .input {
                width: 60px;
                margin: 4px 0 16px;
                text-align: left !important;
              }
            }
          }
        }
      }
    }
  }
}
</style>
