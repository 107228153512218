<template>
  <div>
    <a-modal
      v-model="visible"
      title="Agent Link"
      @ok="() => handleOk()"
      @cancel="() => handleCancel()"
      centered
      :footer="null"
      wrapClassName="custom-confirm"
    >
      <div class="dialog-container">
          <div class="copy-text">
            <div class="text">{{ agentLink }}</div>
            <a-icon class="copy-icon" @click="copyAgentLink" type="copy"></a-icon>
          </div>
          <div class="capture-container" id="captureDiv">
            <div class="header">
              <img class="avatar" src="@/assets/wel/avatar_test.png"/>
              <div class="did-name">{{ DIDFullName }}</div>
            </div>
            <div class="footer">
              <div class="left">
                <div class="logo-text">
                  <img src="@/assets/wel/logo_01.png" alt="">
                  DIDDAO
                </div>
                <div class="text">DAOG ID NFT https://diddao.io </div>
              </div>
              <div class="right">
                <img :src="dataUrl"/>
              </div>
            </div>
          </div>
          <div class="save-btn" @click="createShareImg">Share by Image</div><br>
        </div>
    </a-modal>
  </div>
</template>

<script>
// import { createCanvas } from 'canvas'
import html2canvas from 'html2canvas'
import { copy } from '@/utils/func'
export default {
  name: 'share',
  components: {
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    dataUrl: {
      type: String,
      require: true
    },
    agentLink: {
      type: String,
      require: true
    }
  },

  data () {
    return {
    }
  },

  computed: {
    visible: {
      get () {
        return this.showDialog
      },

      set (val) {
        this.$emit('visible', val)
      }
    },

    DIDFullName () {
      return this.agentLink.split('=')[1]
    }
  },

  mounted () {
  },

  methods: {
    handleCancel () {
    },

    createShareImg () {
      const opt = {
        useCORS: true,
        allowTaint: true
      }

      html2canvas(document.querySelector('#captureDiv'), opt)
        .then(canvas => {
          console.log('captureDiv:', canvas)
          shareByImage(canvas.toDataURL('image/png'), 'DAOG_ID_NFT.png')
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          // this.$emit('visible', false)
        })

      async function shareByImage (uri, filename) {
        // Current system support share API
        if (navigator.share && navigator.canShare) {
          openShare(uri, filename)
        } else {
          simulateDownloadImage(uri, filename)
        }
      }

      async function openShare (uri, filename) {
        // Convert dataUrl into blob using browser fetch API
        const blob = await (await fetch(uri)).blob() // {size: 28241, type: 'image/png'}

        // Create file form the blob
        const imgFile = new File([blob], filename, { type: blob.type })

        // Check if the device is able to share these files then open share dialog
        if (navigator.canShare({ files: [imgFile] })) {
          try {
            await navigator.share({
              // text: window.title,
              // url: window.location.href,
              files: [imgFile], // Array of files to share
              title: 'Share to Earn' // Share dialog title
            })

            this.$message.success('Shared successfully!')
          } catch (err) {
            this.$message.error('Sharing failed. Please try again later.')
            console.log('Sharing failed:', err)
          }
        } else {
          this.$message.info('The current system does not support sharing this type of file.')
        }
      }

      function simulateDownloadImage (uri, filename) {
        const a = document.createElement('a')
        if (typeof a.download !== 'string') {
          window.open(uri)
        } else {
          const blob = dataURIToBlob(uri)
          a.href = URL.createObjectURL(blob)
          console.log(a.href)
          a.download = filename
          document.body.appendChild(a)
          a.click()
          URL.revokeObjectURL(a.href)
          document.body.removeChild(a)
        }
      }

      function dataURIToBlob (dataURI) {
        const binStr = atob(dataURI.split(',')[1])
        const len = binStr.length
        const arr = new Uint8Array(len)

        for (let i = 0; i < len; i++) {
          arr[i] = binStr.charCodeAt(i)
        }
        return new Blob([arr])
      }
    },

    copyAgentLink () {
      copy(this.agentLink)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .ant-modal {
  width: 380px !important;
}
::v-deep .ant-modal-content {
  width: 380px;
  min-height: 735px;
}
.dialog-container {
  .copy-text {
    display: flex;
    align-items: center;
    .text {
      width: 100%;
      color: $valueColor;
      font-size: 16px;
      font-weight: 300;
      white-space: nowrap;
      overflow-y: auto;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
    .copy-icon {
      margin-left: 10px;
      font-size: 16px;
        &:active {
          color: #b15be6;
        }
      }
    }
  .capture-container {
    width: 100%;
    padding: 20px;
    margin: 0 auto;
    text-align: left;
    background: #FEFEFE;
    .header {
      min-height: 369px;
      margin-bottom: 60px;
      border-bottom: 2px dashed #ddd;
      .avatar {
        width: 100%;
        min-height: 292px;
        margin: 0 auto;
        background: $bgColor;
      }
      .did-name {
        margin-top: 24px;
        margin-bottom: 24px;
        color: $blueOth;
        font-size: 18px;

        &::before {
          margin-right: 16px;
          content: 'DAOG ID';
          color: #2C3E50;
          font-weight: bold;
          white-space: nowrap;
        }
      }
    }
    .footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        color: $blueOth;
        font-size: 16px;
        .logo-text {
          font-weight: bold;
          margin-bottom: 16px;

          img {
            width: 24px;
            padding-bottom: 4px;
          }
        }
        .text {
          color: #aaa;
          font-size: 14px;
        }
      }
      .right {
        img {
          width: 68px;
          height: 68px;

          &:not([src]) {
            opacity: 0;
          }
        }
      }
    }
  }
  .save-btn {
    width: 142px;
    height: 36px;
    margin: 24px auto 0;
    color: #fff;
    text-align: center;
    line-height: 36px;
    border-radius: 8px;
    border-style: none;
    background: $mainBtnColor;
    cursor: pointer;
    user-select: none;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
