<template>
  <div class="home">
    <pageHeader></pageHeader>
    <div class="body-container">
      <HomeView v-if="pageMode === 'home'"></HomeView>
      <template v-if="pageMode === 'app'">
        <tabs v-model="tab.active" @change="changeTab"></tabs>

        <template v-if="tab.active === 0">
          <div class="search-container">
            <domain-search
              @search="handleSearchResult" :fullName="fullName">
            </domain-search>
          </div>
          <div class="search-result-container" v-if="showSearchResult && !beforeLoading">
            <div class="top-bar">
              <div class="top-bar-left">
                <div class="invalid" v-if="isInvalid"> {{ $t('registerTag.invalid') }}</div>
                <div class="available" v-else-if="isAvailable"> {{ $t('registerTag.available') }} </div>
                <div class="available" v-else-if="isReselling"> {{ $t('registerTag.reselling') }} </div>
                <div class="available" v-else-if="isRegistered"> {{ $t('registerTag.registered') }} </div>
              </div>

              <div class="top-bar-right">
                <div class="input-icon" v-if="isAvailable">
                  <input
                    class="input"
                    v-model.trim="input.value"
                    :readonly="isReadonly"
                    placeholder="Enter the DID full name of the agent <Required>"
                    spellcheck="false"
                  >
                </div>

                <div class="error" v-else-if="isInvalid">
                  <a-icon class="error-icon" type="close-circle" theme="filled"/>
                  {{ invalidTip }}
                </div>

                <div class="input-icon" v-else :class="{disabled: true}">
                  <input
                    class="input"
                    v-model.trim="input.value"
                    placeholder="Enter the DID full name of the agent <Required>"
                    spellcheck="false"
                  >
                </div>

                <div class="button-loading" v-if="chainId === supportChainId && (isAvailable || isReselling)">
                  <div class="primary-btn" @click="handlePrimaryClick" :class="{disabled: register.loading}">
                    {{ register.step === 3 ? $t('primaryBtn.viewDetails') : $t('primaryBtn.register') }}
                  </div>
                </div>

                <div class="button-loading" v-else>
                  <div class="primary-btn" :class="{disabled: true}">
                      {{ $t('primaryBtn.register') }}
                  </div>
                </div>
              </div>
            </div>

            <template>
              <div class="search-result-content">
                <div class="content-body">
                  <div class="consume-container" v-show="!isInvalid">
                    <div class="item" @click="goDocsPage">
                      <span class="name">Registered Amount</span>
                      {{ DIDInfo.consumed }}
                   </div>
                   <div class="item" @click="goDocsPage">
                      <span class="name">Available Amount</span>
                      {{ DIDInfo.consumable }}
                   </div>
                  </div>
                  <div class="step-container" :class="{disabled: isInvalid || isRegistered}">
                    <div class="step-item" :class="{active: register.step > 0}">
                      <div class="prefix">
                        <div v-if="!isAvailable && !isReselling" class="close">
                          <a-icon type="close" />
                        </div>

                        <div v-else-if="register.step > 1" class="check">
                          <a-icon type="check" />
                        </div>

                        <div v-if="register.step === 1">
                          <a-spin/>
                        </div>
                      </div>
                      <div>1 {{ $t('registerStep.request') }}</div>
                    </div>
                    <a-icon type="right" />
                    <div class="step-item" :class="{active: register.step > 1}">
                      <div class="prefix">
                        <div class="check" v-if="register.step > 2">
                          <a-icon type="check" />
                        </div>
                        <a-spin v-if="register.step === 2"/>
                      </div>
                      <div>2 {{ $t('registerStep.wait') }}</div>
                    </div>
                    <a-icon type="right" />
                    <div class="step-item" :class="{active: register.step > 2}">
                      <div class="prefix">
                        <div class="check" v-if="register.step === 3">
                          <a-icon type="check" />
                        </div>
                      </div>
                      <div>3 {{ $t('registerStep.complete') }}</div>
                    </div>
                  </div>
                  <div class="did-name">
                    <div v-if="isInvalid" class="invalid-name"> {{ searchResult.fullName }}</div>
                    <div v-else class="did-name-value" >{{ searchResult.fullName }}</div>
                  </div>
                  <div class="price-container">
                    <div class="price">
                      <span>{{ DIDInfo.priceR}}</span>
                      <span class="symbol">MATIC</span>
                      <!-- <img class="ethereum-icon" src="@/assets/registration/ethereum.svg"> -->
                      <img class="matic-token" src="@/assets/network/matic-token.png">
                    </div>
                    <div class="price">
                      <span>{{ DIDInfo.price }}</span>
                      <span class="symbol">MATIC</span>
                      <img class="matic-token" src="@/assets/network/matic-token.png">
                    </div>
                  </div>
                  <div class="reg-tip">
                    <div class="label">
                      <span class="key">Likes:</span>
                      <span class="value">{{ DIDInfo.likes }}</span>
                    </div>
                    <div class="line"></div>
                    <div class="label" @click="getAddrDetails(DIDInfo.reserver)">
                      <span class="key">Reserver:</span>
                      <span class="value">{{ DIDInfo.reserver }}</span>
                    </div>
                    <div class="line"></div>
                    <div class="label" @click="getAddrDetails(DIDInfo.owner)">
                      <span class="key">Owner:</span>
                      <span class="value">{{ DIDInfo.owner }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div class="lists">
              <div class="list-container">
                <a-tooltip placement="top">
                  <span slot="title">Add or remove</span>
                  <div class="btn" @click="ListHandler('like')" :class="{disabled: disableLike}">
                    Like
                  <a-icon v-if="inLikedList" type="heart" theme="filled" style="color: #E1251B; font-size: 16px; padding-top: 4px;"/>
                  <a-icon v-else type="heart" style="color: #666; font-size: 16px; padding-top: 4px;"/>
                    {{ likedAmount }}
                  </div>
                </a-tooltip>

                <div class="dids">
                  <div
                    class="item"
                    :class="{'line-through': errorList.name === 'like' && errorList.index === index}"
                    v-for="(item, index) in likedList" :key="index"
                    @click="getActiveIndex('like', index)"
                  >
                    {{ item + suffixFlag}}
                    <a-tooltip placement="top">
                      <span slot="title">Remove DID</span>
                      <img class="delete-icon"
                        v-show="likedListActiveIndex === index"
                        @click="deleteItemHandler('like')"
                        src="@/assets/registration/delete.png"
                      >
                    </a-tooltip>
                  </div>
                </div>
              </div>

              <div class="list-container">
                <a-tooltip placement="top">
                  <span slot="title">Add or remove</span>
                  <div class="btn" @click="ListHandler('reserve')" :class="{disabled: disableReserve}">
                    Reserve
                    <a-icon v-if="inReservedList" type="star" theme="filled" style="color: #E1251B; font-size: 16px; padding-top: 4px;" />
                    <a-icon v-else type="star" style="color: #666; font-size: 16px; padding-top: 4px;" />
                    {{ reservedAmount  }}
                  </div>
                </a-tooltip>

                <div class="dids">
                  <div
                    class="item"
                    :class="{'line-through': errorList.name === 'reserve' && errorList.index === index}"
                    v-for="(item, index) in reservedList" :key="index"
                    @click="getActiveIndex('reserve', index)"
                  >
                    {{ item + suffixFlag }}
                    <a-tooltip placement="top">
                      <span slot="title">Remove DID</span>
                      <img class="delete-icon"
                        v-show="reservedListActiveIndex === index"
                        @click="deleteItemHandler('reserve')"
                        src="@/assets/registration/delete.png"
                      >
                    </a-tooltip>
                </div>
                </div>
              </div>

              <div class="list-container">
                <a-tooltip placement="top">
                  <span slot="title">Add or remove</span>
                  <div class="btn" @click="ListHandler('register')" :class="{disabled: disableRegister}">
                    Cart
                  <a-icon v-if="inRegisteredList" type="shopping-cart" style="color: #E1251B; font-size: 22px;"/>
                  <a-icon v-else type="shopping-cart" style="color: #666; font-size: 22px;"/>
                    {{ registeredAmount }}
                  </div>
                </a-tooltip>
                <div class="dids">
                  <input
                    type="text"
                    class="agent"
                    v-show="agentError"
                    v-model="input.value"
                    placeholder="Enter the DID full name of the agent <Required>"
                    spellcheck="false"
                  >
                  <div
                    class="item"
                    :class="{'line-through': errorList.name === 'register' && errorList.index === index}"
                    v-for="(item, index) in registeredList" :key="index"
                    @click="getActiveIndex('register', index)"
                  >
                    {{ item + suffixFlag }}
                    <a-tooltip placement="top">
                      <span slot="title">Remove DID</span>
                      <img class="delete-icon"
                        v-show="registeredListActiveIndex === index"
                        @click="deleteItemHandler('register')"
                        src="@/assets/registration/delete.png"
                      >
                    </a-tooltip>
                  </div>
                </div>
              </div>
            </div>
            <div class="button-container" v-if="chainId === supportChainId && (isAvailable || isReselling)">
              <div class="primary-btn" @click="handlePrimaryClick" :class="{disabled: register.loading}">
                {{ register.step === 3 ? $t('primaryBtn.viewDetails') : $t('primaryBtn.register') }}
              </div>
            </div>

            <div class="button-container" v-else>
              <div class="primary-btn" :class="{disabled: true}">
                {{ $t('primaryBtn.register') }}
              </div>
            </div>
          </div>

          <div class="windmill" v-else-if="!beforeLoading && !searchFailed">
            <div class="windmill-container">
              <img src="@/assets/registration/windmill-2.png" @load="imgLoaded"/>
              <div class="image-text" v-show="showImgText">DAOG ID</div>
            </div>
          </div>

          <div class="search-failed" v-else-if="searchFailed">
            <div class="">
              <span class="text">⚠️ Search failed, please try again.</span>
            </div>
          </div>

          <div class="before-search-result-container" v-show="beforeLoading && !showSearchResult">
            <div class="before-loading">
              <a-spin tip="Loading..." size="large" :style="{color:'#333'}"/>
            </div>
          </div>
        </template>

        <template v-if="tab.active === 1">
          <!-- batch -->
          <div class="batch">
            <batchOperations></batchOperations>
          </div>
        </template>

        <template v-if="tab.active === 2">
          <!-- mydomain -->
          <div class="mydomain">
            <my-domain @changeTab="changeTabHandler"></my-domain>
          </div>
        </template>

        <!-- 新选项卡内容 -->
        <template v-if="tab.active === 3">
          <div class="derivatives">
            <derivatives @changeTab="changeTabHandler"></derivatives>
          </div>
        </template>
      </template>
    </div>

    <pageFooter></pageFooter>

    <a-modal
      v-model="dialog.show"
      title="Fee Details"
      @ok="() => handleOk()"
      @cancel="() => handleCancel()"
      ok-text="Confirm"
      cancel-text="Cancel"
      centered
      wrapClassName="custom-confirm"
    >
      <div class="dialog-container">

        <div class="fee-name">
          <div class="name">Registers</div>
          <div class="name">Register Orders</div>
          <div class="name">Reserves</div>
          <div class="name">Reserve Orders</div>
          <div class="name">Total expenses</div>
        </div>

        <div class="price">
          <div>{{ cost.registers }}</div>
          <div>{{ cost.orders }}</div>
          <div>{{ cost.reserves }}</div>
          <div>{{ cost.reserveOrders }}</div>
          <div>{{ cost.total }}</div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import domainSearch from '@/components/domainSearch'
import pageHeader from '@/components/layout/pageHeader'
import pageFooter from '@/components/layout/pageFooter'
import tabs from '@/components/tabs'
import myDomain from '@/components/myDomain.vue'
import derivatives from '@/components/derivatives.vue'
import batchOperations from '@/components/batchOperations.vue'
import HomeView from '@/components/wel.vue'
import { mapGetters, mapActions } from 'vuex'

import {
  formatEther,
  getTxDetails,
  getAddrDetails,
  errorHandler,
  createWalletConnect
} from '@/utils/func'

const zeroAddr = process.env.VUE_APP_ZERO_ADDR
const zeroNode = process.env.VUE_APP_ZERO_NODE

export default {
  name: 'Registration',
  components: {
    HomeView,
    pageHeader,
    pageFooter,
    domainSearch,
    tabs,
    batchOperations,
    myDomain,
    derivatives
  },

  data () {
    return {
      showImgText: false,
      agentError: false,
      showSearchResult: false,
      searchFailed: false,
      isInvalid: false,
      accountChanged: false,
      invalidTip: '',
      agentNode: '',
      suffixFlag: '',
      fullName: {},
      beforeLoading: false,
      errorList: {
        name: '',
        index: null
      },
      dialog: {
        show: false
      },
      input: {
        value: ''
      },
      searchResult: {
        // loading: false,
        alias: '',
        suffix: '',
        fullName: ''
      },
      tab: {
        active: 0,
        listPath: [
          '/dapp',
          '/batch',
          '/names',
          '/derivatives'
        ]
      },
      register: {
        step: 0,
        loading: false,
        receipt: {}
      },

      cost: {
        total: '',
        totalWei: '',
        orders: '',
        registers: '',
        reserves: '',
        reserveOrders: ''
      },

      DIDInfo: {
        askerNode: '',
        targetNode: '',
        consumable: undefined,
        consumed: undefined,
        priceR: 0,
        price: 0,
        likes: 0,
        reserver: '',
        owner: '',
        taker: '',
        takerR: ''
      },
      likedList: [],
      reservedList: [],
      newReserveList: [],
      reserveOrderList: [],
      newList: [],
      resaleList: [],
      registeredList: [],
      likedListActiveIndex: null,
      reservedListActiveIndex: null,
      registeredListActiveIndex: null
    }
  },

  computed: {
    ...mapGetters(['address', 'chainId', 'reverseFullName']),

    // searchResultShow () {
    //   return !this.searchResult.loading
    // },

    supportChainId () {
      return process.env.VUE_APP_SUPPORT_CHAIN_ID
    },

    isReadonly () {
      return (this.showAgent && !this.agentError) || this.register.loading
    },

    isEmpty () {
      return this.likedList.length === 0 &&
        this.reservedList.length === 0 &&
        this.registeredList.length === 0
    },

    isOrderHolder () {
      return this.DIDInfo.owner.toLocaleLowerCase() === this.address.toLocaleLowerCase()
    },

    isOrderHolderR () {
      return this.DIDInfo.reserver.toLocaleLowerCase() === this.address.toLocaleLowerCase()
    },

    isTaker () {
      return this.DIDInfo.taker.toLocaleLowerCase() === this.address.toLocaleLowerCase()
    },

    isTakerR () {
      return this.DIDInfo.takerR.toLocaleLowerCase() === this.address.toLocaleLowerCase()
    },

    isDesignatedTaker () {
      return this.DIDInfo.taker && this.DIDInfo.taker !== zeroAddr
    },

    isDesignatedTakerR () {
      return this.DIDInfo.takerR && this.DIDInfo.takerR !== zeroAddr
    },

    noReverseDID () {
      // user 没有反解析DID
      return this.DIDInfo.askerNode && this.DIDInfo.askerNode === zeroNode
    },

    disableLike () {
      return this.isInvalid ||
        this.noReverseDID ||
        this.isRegistered ||
        this.isReselling ||
        this.likeLimited ||
        this.inRegisteredList ||
        this.likedList.length > 9 ||
        (this.suffixFlag && this.suffixFlag !== this.searchResult.suffix) ||
        this.register.step > 0
    },

    disableReserve () {
      return this.isInvalid ||
        this.isRegistered ||
        this.isReselling ||
        (this.isReserved && this.DIDInfo.priceR === 0) ||
        this.inRegisteredList ||
        (this.suffixFlag && this.suffixFlag !== this.searchResult.suffix) ||
        this.register.step > 0
    },

    disableRegister () {
      return this.isInvalid ||
        this.isRegistered ||
        this.inLikedList ||
        this.inReservedList ||
        (this.suffixFlag && this.suffixFlag !== this.searchResult.suffix) ||
        this.register.step > 0
    },

    // state [崭新的; 转卖中; 已注册; 无效]
    isAvailable () {
      // Owner 为零地址并且价格存在(大于0)
      return this.DIDInfo.owner === zeroAddr && this.DIDInfo.price > 0
    },
    isReselling () {
      // Owner 不为零地址并且价格存在（大于0）
      return this.DIDInfo.owner !== zeroAddr && this.DIDInfo.price > 0
    },

    isRegistered () {
      // Owner 不为零地址并且价格等于0
      return this.DIDInfo.owner !== zeroAddr && this.DIDInfo.price === 0
    },

    isNewReverse () {
      return this.DIDInfo.reserver === zeroAddr
    },

    isDerivativeOrder () {
      // Derivative order
      if (this.isReserved) {
        if (this.DIDInfo.priceR > 0) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },

    isReserved () {
      return this.DIDInfo.reserver && this.DIDInfo.reserver !== zeroAddr
    },

    likeLimited () {
      return this.DIDInfo.likes && this.DIDInfo.likes > 99
    },
    inLikedList () {
      return this.likedList.includes(this.searchResult.alias)
    },
    inReservedList () {
      return this.reservedList.includes(this.searchResult.alias)
    },
    inRegisteredList () {
      return this.registeredList.includes(this.searchResult.alias)
    },

    likedAmount () {
      return this.likedList.length
    },
    reservedAmount () {
      return this.reservedList.length
    },
    registeredAmount () {
      return this.registeredList.length
    },

    showAgent () {
      return this.$route.query?.agent
    },
    pageMode () {
      return this.$route.path === '/' ? 'home' : 'app'
    }
  },

  created () {
    const tabPathIndex = this.tab.listPath.findIndex(item => item === this.$route.path)
    this.tab.active = tabPathIndex !== -1 ? tabPathIndex : 0
    this.getRouteParams()
    this.initFunc()
    window.addEventListener('beforeunload', this.beforeunloadHandler)
  },

  beforeDestroy () {
    if (window.walletConnectWeb3Provider) {
      window.walletConnectWeb3Provider.removeAllListeners()
    } else if (window.ethereum) {
      window.ethereum.removeAllListeners()
    }
    window.removeEventListener('beforeunload', this.beforeunloadHandler)
  },

  methods: {
    ...mapActions([
      'setLogin',
      'setAddress',
      'setChainId',
      'setReverseFullName'
    ]),

    changeTab (index) {
      this.tab.active = index
      this.$router.replace(this.tab.listPath[index])
    },

    changeTabHandler (obj) {
      this.tab.active = obj.active
    },

    goDocsPage () {
      window.open('https://diddao.gitbook.io/docs')
    },

    getReverseFullName (addr) {
      if (this.reverseFullName) return

      console.log('Reverse arg:', addr)

      this.$contracts.resolver().reverse(addr).then(res => {
        console.log('Reverse:', res)
        this.setReverseFullName(res[1])
      }).catch(() => {
        // console.log(err)
      })
    },

    getAddrDetails (addr) {
      getAddrDetails(this.chainId, addr)
    },

    async handlePrimaryClick () {
      if (this.register.step === 0) {
        if (this.isEmpty) {
          this.$message.info('No DID Names will be processed !')
          return
        }

        if (!this.input.value && this.newList.length > 0) {
          this.$message.info('Please enter the DID full name of the agent <Required>')
          this.agentError = true
          return
        }

        this.agentError = false
        this.errorList.name = ''
        this.errorList.index = null

        this.register.step = 1

        if (this.searchResult.suffix === '.i') {
          // TODO
        }

        if (this.searchResult.suffix === '.o') {
          // TODO
        }

        this.launchToChain()
      } else if (this.register.step === 3) {
        getTxDetails(this.chainId, this.register.receipt.transactionHash)
      }
    },

    async handleSearchResult (obj) {
      this.beforeLoading = obj.beforeLoading
      this.showSearchResult = false
      this.isInvalid = false
      this.invalidTip = ''
      this.searchFailed = false
      this.agentError = false
      this.likedListActiveIndex = null
      this.reservedListActiveIndex = null
      this.registeredListActiveIndex = null
      this.emptyTxData()
      this.DIDInfo = {}
      this.searchResult = {}

      this.searchResult = this.$options.data().searchResult
      this.searchResult = { ...obj }

      await this.searchFromChain(this.searchResult.alias)
      if (!this.searchFailed) {
        this.showSearchResult = true
      }
      this.beforeLoading = false

      // DID name 无效
      if (this.isInvalid) {
        const length = obj.alias.length
        if (length < 4) {
          this.invalidTip = 'DID name cannot be less than 4 characters.'
          return
        }
        if (length > 32) {
          const msg = 'DID name cannot be longer than 32 characters.'
          this.invalidTip = msg
          this.searchResult.fullName = msg
          return
        }
        this.invalidTip = 'Illegal characters < Note: can only contain 0~9, -, a~z >.'
      }
    },

    handleCancel () {
      this.register.step = 0
      this.register.loading = false
    },

    handleOk () {
      let count = 0
      let listName = ''
      const orders = []
      const ordersR = []
      const askerNode = this.DIDInfo.askerNode ? this.DIDInfo.askerNode : zeroNode

      if (this.newList.length > 0) {
        count++
        listName = 'new'
      }

      if (this.resaleList.length > 0) {
        count++
        listName = 'resale'
        this.resaleList.forEach((item) => {
          orders.push(item.nodeHash)
        })
      }

      if (this.newReserveList.length > 0) {
        count++
        listName = 'newReserve'
      }

      if (this.reserveOrderList.length > 0) {
        count++
        listName = 'reserveOrder'
        this.reserveOrderList.forEach((item) => {
          ordersR.push(item.nodeHash)
        })
      }

      if (this.likedList.length > 0) {
        count++
        listName = 'liked'
      }

      this.dialog.show = false

      if (count === 1) {
        this.funcSelector(listName, askerNode, orders, ordersR)
      } else {
        const args = [
          0,
          askerNode,
          this.agentNode,
          [orders, ordersR],
          this.newList,
          this.newReserveList,
          this.likedList
        ]

        console.log('launchToChain args: ', args)

        this.$contracts.multiCall(true).launchToChain(
          ...args,
          {
            // from: this.address,
            value: this.cost.totalWei
          }
        ).then(result => {
          this.register.step = 2
          return result.wait()
        })
          .then(receipt => {
            this.register.step = 3
            this.register.receipt = receipt
            if (this.registeredList.length > 0) {
              this.getReverseFullName(this.address)
            }
            this.emptyList()
            this.$message.success('Transaction executed successfully !')
          })
          .catch(err => {
            this.launchCheck()
            this.register.step = 0
            errorHandler(err)
          })
          .finally(() => {
            this.register.loading = false
          })
      }
    },

    async searchFromChain (targetName) {
      const asker = this.address
      const args = [
        asker,
        0,
        targetName
      ]

      console.log('search args: ', asker)

      try {
        const info = await this.$contracts.multiCall().search(...args)
        this.DIDInfo.askerNode = info.askerNode
        this.DIDInfo.targetNode = info.targetNode
        this.DIDInfo.consumable = info.consumable.toNumber()
        this.DIDInfo.consumed = info.consumed.toNumber()
        this.DIDInfo.priceR = info.priceR.isZero() ? 0 : formatEther(info.priceR)
        this.DIDInfo.price = info.price.isZero() ? 0 : formatEther(info.price)
        this.DIDInfo.likes = info.likes?.toNumber()
        this.DIDInfo.reserver = info.reserver
        this.DIDInfo.owner = info.owner
        this.DIDInfo.taker = info.taker
        this.DIDInfo.takerR = info.takerR

        console.log('Info: ', info)
        console.log('DIDInfo:', JSON.stringify(this.DIDInfo))
      } catch (err) {
        // RPC网络或域名无效
        if (err.reason === 'Invalid name') {
          this.isInvalid = true
          console.log('err', err.reason, err.code)
        } else {
          this.searchFailed = true
          this.$message.error('Network error, query failed.')
        }
        console.log(err)
      }
    },

    async launchToChain () {
      const errBaseCode = 1e9
      const { state } = await this.launchCheck()

      if (state === 0 || ((state >= errBaseCode && state < 2 * errBaseCode) && this.newList.length === 0)) {
        this.dialog.show = true
      } else {
        this.handleCancel()
      }
    },

    async launchCheck () {
      let agentSuffix, agentName
      const iHash = process.env.VUE_APP_I_NODE
      const oHash = process.env.VUE_APP_O_NODE

      if (!this.input.value || this.newList.length === 0) {
        // agent.i
        agentName = 'agent'
        agentSuffix = iHash
      } else {
        switch (this.input.value.split('.')[1]) {
          case 'i':
            agentSuffix = iHash
            break
          case 'o':
            agentSuffix = oHash
            break
          default:
            this.$message.error('Agent DID error, unsupported domain suffix.')
            this.agentError = true
            this.handleCancel()
            return
        }
        agentName = this.input.value.split('.')[0]
      }

      this.register.loading = true
      const orders = []
      const ordersR = []

      if (this.resaleList.length > 0) {
        this.resaleList.forEach((item) => {
          orders.push(item.nodeHash)
        })
      }

      if (this.reserveOrderList.length > 0) {
        this.reserveOrderList.forEach((item) => {
          ordersR.push(item.nodeHash)
        })
      }

      const errBaseCode = 1e9
      const askerNode = this.DIDInfo.askerNode === zeroNode ? zeroNode : this.DIDInfo.askerNode
      const args = [
        0,
        askerNode,
        agentSuffix,
        agentName,
        [orders, ordersR],
        this.newList,
        this.newReserveList,
        this.likedList
      ]

      console.log('launchCheck args: ', args)

      try {
        const res = await this.$contracts.multiCall().launchCheck(...args)
        console.log('launchCheck:', res)

        const state = res.state.toNumber()
        this.agentNode = res.agentNode
        this.cost.orders = res.costs[0].isZero() ? 0 : formatEther(res.costs[0])
        this.cost.reserveOrders = res.costs[1].isZero() ? 0 : formatEther(res.costs[1])
        this.cost.registers = res.costs[2].isZero() ? 0 : formatEther(res.costs[2])
        this.cost.reserves = res.costs[3].isZero() ? 0 : formatEther(res.costs[3])
        this.cost.totalWei = res.costs[0].add(res.costs[1]).add(res.costs[2]).add(res.costs[3])
        this.cost.total = this.cost.totalWei.isZero() ? 0 : formatEther(this.cost.totalWei)

        if (state === 0) {
          console.log('State right: ', state)
          return { state }
        }

        if (state >= errBaseCode && state < 2 * errBaseCode && this.agentNode === zeroNode) {
          if (this.newList.length > 0) {
            this.agentError = true
            this.$message.error('Agent does not exist, Please check and try again.')
            console.log('Agent error:', state)
          }
          return { state }
        }

        if (state >= 2 * errBaseCode && state < 3 * errBaseCode) {
          this.getErrorList(state - 2 * errBaseCode, 'order')
          this.$message.error(`An error occurred in the ${this.errorList.index + 1}th DID in the cart list. Please delete it and try again.`)
          console.log('Resale orders error:', state, state - 2 * errBaseCode)
        } else if (state >= 3 * errBaseCode && state < 4 * errBaseCode) {
          this.getErrorList(state - 3 * errBaseCode, 'register')
          this.$message.error(`An error occurred in the ${this.errorList.index + 1}th DID in the cart list. Please delete it and try again.`)
          console.log('Registers error with index:', state, state - 3 * errBaseCode)
        } else if (state >= 4 * errBaseCode && state < 5 * errBaseCode) {
          this.getErrorList(state - 4 * errBaseCode, 'reserve')
          console.log(this.errorList.index, this.errorList.name)
          this.$message.error(`An error occurred in the ${this.errorList.index + 1}th DID in the reserves list. Please delete it and try again.`)
          console.log('Reserves error with index:', state, state - 4 * errBaseCode)
        } else if (state >= 5 * errBaseCode && state < 6 * errBaseCode) {
          this.getErrorList(state - 5 * errBaseCode, 'like')
          this.$message.error(`An error occurred in the ${this.errorList.index + 1}th DID in the Likes list. Please delete it and try again.`)
          console.log('Likes error with index:', state, state - 5 * errBaseCode)
        } else if (state >= 6 * errBaseCode && state < 7 * errBaseCode) {
          this.getErrorList(state - 6 * errBaseCode, 'derivativeOrder')
          this.$message.error(`An error occurred in the ${this.errorList.index + 1}th DID in the reserves list. Please delete it and try again.`)
          console.log('Reserve orders error:', state, state - 6 * errBaseCode)
        }

        this.handleCancel()
        return {}
      } catch (err) {
        this.handleCancel()
        errorHandler(err)
      }
    },

    funcSelector (listName, askerNode, orders, ordersR) {
      switch (listName) {
        case 'liked':
          this.batchLike(askerNode)
          break
        case 'newReserve':
          this.batchReserve()
          break
        case 'new':
          this.batchRegister()
          break
        case 'resale':
          this.takeOrders(orders, false)
          break
        case 'reserveOrder':
          this.takeOrders(ordersR, true)
          break
      }
    },

    batchLike (askerNode) {
      const args = [
        this.likedList,
        askerNode
      ]

      console.log('batchLike args:', args)

      this.$contracts.valueMining(true).batchLike(
        ...args,
        {
          value: this.cost.totalWei
        }
      ).then(result => {
        this.register.step = 2
        return result.wait()
      })
        .then(receipt => {
          this.register.step = 3
          this.register.receipt = receipt
          this.emptyList()
          this.$message.success('Transaction executed successfully !')
        })
        .catch(err => {
          this.launchCheck()
          this.register.step = 0
          errorHandler(err)
        })
        .finally(() => {
          this.register.loading = false
        })
    },

    batchReserve () {
      const args = [
        this.newReserveList,
        this.address
      ]

      console.log('batchReserve args:', args)

      this.$contracts.valueMining(true).batchReserve(
        ...args,
        {
          value: this.cost.totalWei
        }
      ).then(result => {
        this.register.step = 2
        return result.wait()
      })
        .then(receipt => {
          this.register.step = 3
          this.register.receipt = receipt
          this.emptyList()
          this.$message.success('Transaction executed successfully !')
        })
        .catch(err => {
          this.launchCheck()
          this.register.step = 0
          errorHandler(err)
        })
        .finally(() => {
          this.register.loading = false
        })
    },

    batchRegister () {
      const args = [
        this.agentNode,
        this.address,
        this.newList
      ]

      console.log('batchRegister args:', args)

      this.$contracts.registrar(true).batchRegister(
        ...args,
        {
          value: this.cost.totalWei
        }
      ).then(result => {
        this.register.step = 2
        return result.wait()
      })
        .then(receipt => {
          this.register.step = 3
          this.register.receipt = receipt
          this.emptyList()
          this.getReverseFullName(this.address)
          this.$message.success('Transaction executed successfully !')
        })
        .catch(err => {
          this.launchCheck()
          this.register.step = 0
          errorHandler(err)
        })
        .finally(() => {
          this.register.loading = false
        })
    },

    takeOrders (orders, isBuffer) {
      const args = [
        orders,
        this.address,
        isBuffer
      ]

      isBuffer ? console.log('Reserve takeOrders args:', args) : console.log('Resale takeOrders args:', args)

      this.$contracts.market(true).takeOrders(
        ...args,
        {
          value: this.cost.totalWei
        }
      ).then(result => {
        this.register.step = 2
        return result.wait()
      })
        .then(receipt => {
          this.register.step = 3
          this.register.receipt = receipt
          this.emptyList()
          if (!isBuffer) {
            this.getReverseFullName(this.address)
          }
          this.$message.success('Transaction executed successfully !')
        })
        .catch(err => {
          this.launchCheck()
          this.register.step = 0
          errorHandler(err)
        })
        .finally(() => {
          this.register.loading = false
        })
    },

    deleteItemHandler (flagStr) {
      switch (flagStr) {
        case 'like':
          this.likedList.splice(this.likedListActiveIndex, 1)
          break
        case 'reserve': {
          const index = this.reserveOrderList.findIndex(obj =>
            obj.alias === this.reservedList[this.reservedListActiveIndex]
          )
          const index1 = this.newReserveList.indexOf(this.reservedList[this.reservedListActiveIndex])
          if (index !== -1) {
            this.reserveOrderList.splice(index, 1)
          }
          if (index1 !== -1) {
            this.newReserveList.splice(index1, 1)
          }
          this.reservedList.splice(this.reservedListActiveIndex, 1)
          break
        }
        case 'register': {
          const index = this.resaleList.findIndex(obj =>
            obj.alias === this.registeredList[this.registeredListActiveIndex]
          )
          const index1 = this.newList.indexOf(this.registeredList[this.registeredListActiveIndex])
          if (index !== -1) {
            this.resaleList.splice(index, 1)
          }
          if (index1 !== -1) {
            this.newList.splice(index1, 1)
          }
          this.registeredList.splice(this.registeredListActiveIndex, 1)
          break
        }
      }
      this.clearListError(flagStr)

      const len = this.likedList.length
      const len1 = this.reservedList.length
      const len2 = this.registeredList.length

      if (!len && !len1 && !len2) {
        this.suffixFlag = ''
      }
    },

    ListHandler (flagStr) {
      let index, arr
      if (!this.suffixFlag) {
        this.suffixFlag = this.searchResult.suffix
      }
      switch (flagStr) {
        case 'like':
          index = this.likedList.indexOf(this.searchResult.alias)
          arr = this.likedList
          break
        case 'reserve':
          index = this.reservedList.indexOf(this.searchResult.alias)
          arr = this.reservedList

          if (this.isOrderHolderR) break
          if (this.isDesignatedTakerR && !this.isTakerR) break

          if (index === -1) {
            if (this.isDerivativeOrder) {
              this.reserveOrderList.push({
                alias: this.searchResult.alias,
                nodeHash: this.DIDInfo.targetNode
              })
            } else if (this.isNewReverse) this.newReserveList.push(this.searchResult.alias)
          } else {
            if (this.isDerivativeOrder) this.reserveOrderList.splice(index, 1)
            else if (this.isNewReverse) this.newReserveList.splice(index, 1)
          }
          break
        case 'register':
          index = this.registeredList.indexOf(this.searchResult.alias)
          arr = this.registeredList

          if (this.isOrderHolder) break
          if (this.isDesignatedTaker && !this.isTaker) break

          if (index === -1) {
            if (this.isReselling) {
              this.resaleList.push({
                alias: this.searchResult.alias,
                nodeHash: this.DIDInfo.targetNode
              })
            } else if (this.isAvailable) this.newList.push(this.searchResult.alias)
          } else {
            if (this.isReselling) this.resaleList.splice(index, 1)
            else if (this.isAvailable) this.newList.splice(index, 1)
          }
          break
      }

      // TODO
      if (index === -1) {
        if (flagStr === 'register') {
          if (this.isOrderHolder) {
            this.$message.info('You already own this DID and are currently selling it.')
            return
          }

          if (this.isDesignatedTaker && !this.isTaker) {
            this.$message.info('This DID order has already specified a buyer, you cannot purchase it.')
            return
          }
        }
        if (flagStr === 'reserve') {
          if (this.isOrderHolderR) {
            this.$message.info('You already own this DID and are currently selling it.')
            return
          }

          if (this.isDesignatedTakerR && !this.isTakerR) {
            this.$message.info('This DID order has already specified a buyer, you cannot purchase it.')
            return
          }
        }

        arr.push(this.searchResult.alias)
      } else {
        arr.splice(index, 1)
        this.clearListError(flagStr)
      }
    },

    emptyTxData () {
      this.register.step = 0
      this.register.loading = false
      this.register.receipt = {}
    },

    emptyList () {
      this.newList = []
      this.likedList = []
      this.resaleList = []
      this.reservedList = []
      this.registeredList = []
      this.newReserveList = []
      this.reserveOrderList = []
    },

    getActiveIndex (flagStr, index) {
      switch (flagStr) {
        case 'like':
          this.likedListActiveIndex = index
          break
        case 'reserve':
          this.reservedListActiveIndex = index
          break
        case 'register':
          this.registeredListActiveIndex = index
          break
      }
    },

    getErrorList (index, listName) {
      switch (listName) {
        case 'register':
          this.errorList.name = listName
          this.errorList.index = this.registeredList.indexOf(this.newList[index])
          break
        case 'order':
          this.errorList.name = 'register'
          this.errorList.index = this.registeredList.indexOf(this.resaleList[index].alias)
          break
        case 'reserve':
          this.errorList.name = listName
          this.errorList.index = this.reservedList.indexOf(this.newReserveList[index])
          break
        case 'derivativeOrder':
          this.errorList.name = 'reserve'
          this.errorList.index = this.reservedList.indexOf(this.reserveOrderList[index].alias)
          console.log(this.reserveOrderList[index].alias)
          break
        case 'like':
          this.errorList.name = listName
          this.errorList.index = index
          break
      }
    },

    clearListError (listName) {
      switch (listName) {
        case 'like':
          if (this.errorList.name === listName) {
            if (this.likedListActiveIndex === this.errorList.index) {
              this.errorList.index = null
              this.errorList.name = ''
            } else {
              this.trackErrorIndex(this.likedListActiveIndex)
            }
          }
          break
        case 'reserve':
          if (this.errorList.name === listName) {
            if (this.reservedListActiveIndex === this.errorList.index) {
              this.errorList.index = null
              this.errorList.name = ''
            } else {
              this.trackErrorIndex(this.reservedListActiveIndex)
            }
          }
          break

        case 'register':
          if (this.errorList.name === listName) {
            if (this.registeredListActiveIndex === this.errorList.index) {
              this.errorList.index = null
              this.errorList.name = ''
            } else {
              this.trackErrorIndex(this.registeredListActiveIndex)
            }
          }
          break
      }
    },

    trackErrorIndex (delIndex) {
      if (this.errorList.index && this.errorList.index > delIndex) {
        this.$set(this.errorList, 'index', this.errorList.index - 1)
      }
    },

    getRouteParams () {
      // 通过路由获取参数
      console.log('agent ===', this.$route.query?.agent)
      console.log('searchFullName === ', this.$route.query?.name)

      if (this.$route.query?.name) {
        const fullName = this.$route.query.name
        this.fullName.name = fullName.split('.')[0]
        this.fullName.suffix = '.' + fullName.split('.').pop()
      }

      if (this.$route.params?.suffix) {
        this.fullName.suffix = this.$route.params.suffix
      }

      if (this.$route.query?.agent) {
        this.input.value = this.$route.query.agent
      }
    },

    async beforeunloadHandler (event) {
      if (!window.localStorage.getItem('walletconnect')) {
        try {
          const accounts = await window.ethereum.request({
            method: 'eth_accounts'
          })
          this.setAddress(accounts[0])
        } catch (err) {
          console.err(err)
        }

        if (this.address) {
          window.localStorage.setItem('user', JSON.stringify({
            address: this.address
          }))
        }
      }
    },

    async initFunc () {
      // TODO
      if (window.localStorage.getItem('walletconnect')) {
        const provider = await createWalletConnect()

        if (window.walletConnectWeb3Provider && provider) {
          // Listen to EIP-1193 events to detect accounts and chain change and also disconnection.
          // Subscribe to accounts change
          window.walletConnectWeb3Provider.on('accountsChanged:', (accounts) => {
            console.log('accountsChanged:', accounts)
            this.setAddress(accounts[0])
          })

          // Subscribe to chainId change
          window.walletConnectWeb3Provider.on('chainChanged', (chainId) => {
            console.log('chainChanged:', chainId)
            this.setChainId('0x' + chainId.toString(16))
          })

          // Subscribe to session connection
          window.walletConnectWeb3Provider.on('connect', () => {
            console.log('connect')
          })

          // Subscribe to session disconnection, default cached provider is cleared,
          window.walletConnectWeb3Provider.on('disconnect', (code, reason) => {
            console.log(code, reason)
            delete window.walletConnectWeb3Provider
            window.localStorage.removeItem(this.address)
            this.setLogin(false)
            this.setAddress('')
            window.location.reload()
          })

          try {
            const signer = await provider.getSigner()
            const chainId = '0x' + (await signer.getChainId()).toString(16)
            const address = await signer.getAddress()

            console.log('Current signer:', address, 'chainId:', chainId)

            if (window.localStorage.getItem(address)) {
              const loginInfo = JSON.parse(window.localStorage.getItem(address))
              const due = loginInfo.timestamp + 24 * 3600 * 1000 * 7
              if (loginInfo.isLogin && due > Date.now()) {
                this.setLogin(loginInfo.isLogin)
                this.setAddress(address)
                this.setChainId(chainId)
              } else {
                window.localStorage.removeItem(address)
              }
            }
          } catch (err) {
            console.log(err)
          }
        }
      } else if (window.ethereum && window.ethereum.isMetaMask) {
        window.ethereum.on('accountsChanged', this.handleAccountsChanged)
        window.ethereum.on('chainChanged', this.handleChainChanged)
        window.ethereum.on('disconnect', this.handleAccountsDisconnect)

        try {
          const chainId = await window.ethereum.request({
            method: 'eth_chainId'
          })
          if (chainId !== this.supportChainId) return
        } catch (err) {
          console.log(err)
          return
        }

        let account
        if (window.localStorage.getItem('user')) {
          const user = JSON.parse(window.localStorage.getItem('user'))
          account = user.address
          window.localStorage.removeItem('user')
        }

        if (window.localStorage.getItem(account)) {
          const loginInfo = JSON.parse(window.localStorage.getItem(account))
          const due = loginInfo.timestamp + 24 * 3600 * 1000 * 7
          if (loginInfo.isLogin && due > Date.now()) {
            this.setLogin(loginInfo.isLogin)
            this.setAddress(loginInfo.address)
            this.setChainId(loginInfo.chainId)
          } else {
            window.localStorage.removeItem(account)
          }
        }
      }
    },

    imgLoaded () {
      this.showImgText = true
    },

    handleAccountsDisconnect () {
      console.log('disconnect')
    },

    handleAccountsChanged (accounts) {
      console.log('accountChanged:', accounts)
      if (this.address) {
        this.accountChanged = true
        window.location.reload()
      }
    },

    handleChainChanged (chainId) {
      this.setChainId(chainId)
      // window.location.reload()
    },

    handleRpcError (err) {
      console.log(err)
      this.$message.error('RPC network error occurred, Refresh the page and try again.')
    }
  }
}
</script>

<style lang="scss" scoped>
// $border-color: #bfa;

// @mixin scrollBar($thumbColor: #F0F2F5, $trackColor: transparent, $length: 12px) {
//   width: $length;
//   height: $length;
//   background: $trackColor;
//   z-index: 999;

//   // 轨道
//   &-track {
//     border-radius: 10px;
//     background: $trackColor;
//   }

//   // 滑块样式
//   &-thumb {
//     border: none;
//     background: $thumbColor;
//     border-radius: 2px;

//     &:hover {
//       background: $thumbColor;
//     }
//   }
// }

.dialog-container{
  display: flex;
  width: 100%;
  height: 120px;
  text-align: left;
  overflow-y: auto;
  .fee-name {
    color: #555;
    .name:last-child {
      margin-top: 12px;
    }
  }
  .price {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    color: red;
    div {
      &:last-child {
        margin-top: 12px;
      }
      &::after {
        content: 'MATIC';
        margin-left: 8px;
        font-style: italic;
      }

    }

  }
}
.home{
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  background-image: linear-gradient(#E9EAFF, #FBFBFF);
  overflow: auto;
  // background: url('~@/assets/mask.png') center no-repeat;
  // background-size: cover;

  // .header{
  //   width: 980px;
  //   height: 60px;
  //   margin: 0 auto;
  //   display: flex;
  //   justify-content: space-between;

  //   .account{
  //     display: flex;

  //     .switch-network{
  //       margin-right: 12px;
  //     }
  //   }
  // }

  .body-container{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    .search-bar{
      width: 890px;
      height: 60px;
      border-radius: 60px;
      display: flex;
      background: #fff;
      border: 1px solid #CFD3F7;
    }
    .search-container {
      padding: 48px 0;
    }
    // test
    .windmill {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1200px;
      min-height: 450px;
      margin-bottom: 50px;
      .windmill-container {
        position: relative;
        width: 500px;
        .image-text {
          position: absolute;
          top: 164px;
          left: 207px;
          font-size: 20px;
          font-weight: 900;
          color: #fff;
          text-shadow: 0 0 6px $blueOth;
          user-select: none;
        }

      img {
        width: 100%;
        user-select: none;
        pointer-events: none;
        animation: mymove 80s infinite;
        animation-timing-function: linear;
        animation-delay: -2s;

        @keyframes mymove {
            from { transform: rotate(0deg) }
            to { transform: rotate(360deg) }
          }
        }
      }
    }
    .search-result-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 1200px;
      // min-height: 578px;
      min-height: 660px;
      margin-bottom: 120px;
      // overflow-y: auto;
      border-radius: 16px;
      background: #fff;
      box-shadow: 0 0 6px $boxShadowColor;
      .before-loading {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .top-bar{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 900px;
        height: 80px;
        border-bottom: 1px solid #edeeff;
        .error {
          padding: 0 16px;
          color: rgba(255, 0, 0, 0.9);
          font-weight: 500;
          &-icon {
            padding-right: 8px;
          }
        }

        &-left {
          width: 14%;
          text-align: center;
          .available, .invalid {
            padding: 6px 18px;
            color: $blue;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            border-radius: 4px;
            background: rgba($color: $blue, $alpha: 0.1);
          }
          .invalid {
            color: red;
          }
        }

        &-right {
          flex: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .input-icon {
            position: relative;
            margin-left: 48px;
            .icon {
              position: absolute;
              top: 6px;
              right: 2px;
              font-size: 14px;
              color: $blue;
            }
            .input {
              width: 460px;
              height: 24px;
              font-size: 16px;
              color: $blueOth;
              font-weight: 500;
              border-style: none;
              border-bottom: 1px solid #ddd;
              text-align: center;
              outline: none;
              &:hover {
                border-bottom: 1px solid #C4C7CE;
              }

              &::placeholder {
                font-size: 14px;
                font-weight: 400;
                color: #666;
                opacity: 0.6;
              }
          }
      }
        .button-loading {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .primary-btn {
            padding: 0 16px;
            line-height: 40px;
            border-radius: 40px;
            background: $mainBtnColor;
            color: #FFF;
            cursor: pointer;
            user-select: none;

            &:active {
              opacity: 0.6;
            }
          }
        }
      }
    }

      .search-result-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 60px auto;
        font-weight: 500;

        .did-instructions {
          width: 932px;
          height: 560px;
          padding:  32px 16px 24px;
          color: #FFC900;
          // // overflow-y: auto;
          // // firefox
          // scrollbar-width: none;

          // // chrome edge safari
          // &::-webkit-scrollbar {
          //   display: none;
          // }
        // &::-webkit-scrollbar {
        //     width: 0;
        //     height: 0;
        //   }
        //   &::-webkit-scrollbar{
        //   @include scrollBar(rgba(255, 255, 255, 0.2), transparent, 8px)
        // }
        //   &::-webkit-scrollbar {
        //     /*滚动条整体样式*/
        //     background-color: #bfa;

        //     /*高宽分别对应横竖滚动条的尺寸*/
        //     height: 8px;
        // }

          .title {
            color: #FFC900;
            text-align: center;
          }
          .desc {
            font-size: 16px;
            border-bottom: 1px solid #CFD3F7;
            padding-bottom: 12px;
          }
          .text {
            padding-right: 6px;
            text-align: justify;
            color: #FFC900;
            font-size: 15px;
            line-height: 40px;
          }
        }
        .unavailable-icon{
          margin-top: -80px;
        }
        .text-tip {
          font-size: 20px;
          font-weight: 400;
        }
      }

      .button-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 900px;
        margin: 24px 0;
        .primary-btn {
          padding: 0 16px;
          line-height: 40px;
          border-radius: 40px;
          background: $mainBtnColor;
          color: #FFF;
          cursor: pointer;
          user-select: none;

          &:active {
            opacity: 0.6;
          }
        }
      }
      .lists {
        display: flex;
        width: 900px;
        height: 230px;

        .list-container {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          align-items: center;
          width: 290px;
          height: 100%;
          margin-right: 16px;

          &:last-child {
            margin-right: 0;
          }
          .btn {
            padding: 4px 12px;
            font-size: 14px;
            border-radius: 4px;
            background-color: $bgColor;
            cursor: pointer;
            user-select: none;

            &:active {
              opacity: 0.6;
            }
          }
          .dids {
            display: flex;
            width: 100%;
            height: 100%;
            margin-top: 24px;
            border-radius: 4px;
            box-shadow: 0 0 6px $boxShadowColorOth;
            border: 1px solid #ccc;
            flex-direction: column;
            padding: 4px 2px;
            overflow: auto;
            // firefox
            scrollbar-width: none;

            // chrome edge safari
            &::-webkit-scrollbar {
              display: none;
            }

            .agent {
              padding-left: 4px;
              margin-bottom: 8px;
              color: $blueOth;
              text-align: center;
              border-style: none;
              border-bottom: 1px solid #ddd;
              outline: none;

              &::placeholder {
                font-size: 12px;
                color: #666;
                opacity: 0.6;
              }
            }
            .item {
              position: relative;
              margin-bottom: 2px;
              padding-left: 4px;
              border-radius: 2px;
              color: $blueOth;
              font-size: 14px;
              height: 24px;
              line-height: 24px;
              text-align: left;
              cursor: pointer;

              &:last-child {
                margin-bottom: 0;
              }

              &:hover {
                color: #333;
                background-color: $bgColor;
              }

              .delete-icon {
                position: absolute;
                right: 1px;
                top: 4px;
                width: 16px;
              }
            }
          }
        }
      }

      .content-body{

        .consume-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          overflow: hidden;
          .item {
            font-size: 18px;
            font-weight: 600;
            color: red;
            cursor: pointer;
            .name {
              color: $valueColor;
              margin-right: 12px;
            }
          }
        }
        .did-name {
          max-width: 900px;
          margin: 80px auto;
          text-align: center;
          font-size: 24px;
          font-weight: 600;

          .did-name-value, .invalid-name {
            display: inline-flex;
            color: $blueOth;
            padding: 8px 32px;
            border-radius: 4px;
            box-shadow: 0px 0px 12px $boxShadowColorOth;

            word-wrap: break-word;
            overflow-wrap: break-word;
            word-break: break-all;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .invalid-name {
            color: #aaa;
            box-shadow: 0px 0px 12px #ccc
          }
        }

        .price-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 12px;
          overflow: hidden;
          .price {
            font-size: 18px;
            font-weight: 600;
            color: red;
            text-align: right;
            .symbol {
              margin-left: 8px;
              font-weight: bold;
              font-style: italic;
            }
            .matic-token {
              width: 20px;
              margin-left: 10px;
              margin-bottom: 5px;
            }
            .ethereum-icon {
              width: 28px;
              margin-bottom: 6px;
            }

            &:first-child::before {
              content: 'Reservation Price';
              color: $valueColor;
              margin-right: 16px;
            }

            &:last-child::before {
              content: 'Price';
              color: $valueColor;
              margin-right: 16px;
            }
          }
        }
        .reg-tip {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          .label {
            color: $blue;
            .value {
              margin-left: 6px;
              color: $valueColor;
            }

            &:not(:first-child) {
                cursor: pointer;
            }

            &:last-child {
              border-right: none;
            }
          }
          .line {
            height: 20px;
            border-right: 1px solid #ddd;
          }
        }
        .step-container{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 900px;
          height: 42px;
          margin-top: 24px;
          border-radius: 24px;
          background-color: $bgColor;
          line-height: 1;

          .step-item{
            width: 280px;
            padding: 0 32px;
            font-size: 14px;
            font-weight: bold;
            display: flex;
            align-items: center;
            color: #999999;

            &.active{
              color: #333333;
            }

            .prefix{
              width: 26px;
              height: 26px;
              margin-right: 16px;
              display: inline-flex;
              align-items: center;
              line-height: 1;

              .check, .close {
                width: 26px;
                height: 26px;
                border-radius: 26px;
                line-height: 30px;
                color: $blue;
                background: rgba($color: $blue, $alpha: 0.3);
              }
              .close {
                line-height: 28px;
                color: red;

                &:last-child {
                  font-size: 16px;
                }
              }

              .ant-spin{
                line-height: 1;
              }
            }
          }
        }
      }

      &.unavailable {
        .top-bar{
          color: #FF3333;
          .available{
            color: #FF3333;
            background: rgba($color: #FF3333, $alpha: 0.1);
          }
        }
      }
    }
    .before-search-result-container, .search-failed {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1200px;
      min-height: 660px;
      border-radius: 16px;
      margin-bottom: 240px;
      background: #fff;
      box-shadow: 0 0 6px $boxShadowColor;
      .before-loading {
        font-size: 100px;
      }
    }
    .search-failed {
      color: $blue;
      font-size: 16px;
    }
    .mydomain, .derivatives, .batch {
      width: 1200px;
      margin: 48px auto;
      min-height: 660px;
      flex: 1;
      margin-bottom: 120px;
    }
  }

  @media screen  and (max-width: $mobileWidth){
    overflow: hidden;
    .body-container{
      .search-container {
        padding: 48px 12px;
        width: 100%;
      }
      .before-search-result-container, .search-failed {
        width: calc(100% - 32px);
      }

      .search-result-container{
        width: calc(100% - 32px);
        margin: 0 16px;
        padding: 0 16px;

        .top-bar{
          width: 100%;
          flex-direction: column;
          height: auto;
          padding: 12px 0;

          .error {
            padding: 24px 12px;
          }
        }

        .top-bar-left{
          width: auto;
        }

        .top-bar-right{
          flex-direction: column;
          width: 100%;

          .input-icon{
            width: 100%;
            margin-left: unset;
            margin: 24px 0;

            .input{
              width: 100%;

              &::placeholder {
                font-size: 12px;
              }
            }
          }

          .button-loading{
            width: auto;

            // .primary-btn{
            //   margin-left: auto;
            // }
          }
        }

        .search-result-content{
          margin: 10px auto;
          width: 100%;

          .did-instructions{
            width: 100%;
            height: auto;
            padding: 16px 0 16px;

            ul{
              padding-left: 16px;
            }

            .text{
              margin-bottom: 28px;
              line-height: 24px;
            }
          }
        }

        .content-body{
          width: 100%;
          .consume-container {
            flex-direction: column;
            align-items: flex-end;

            .item {
              margin-bottom: 24px;
              font-size: 14px;
              .name {
                word-break: break-all;
              }

              &:first-child {
                margin-top: 32px;
              }
            }
          }
          .step-container{
            width: 100%;
            flex-direction: column;
            height: auto;
            padding: 16px 0;
          }
          .price-container {
            flex-direction: column-reverse;
            align-items: flex-end;
            .price {
              font-size: 14px;
              margin-bottom: 24px;
              word-break: break-all;
            }
          }

          .reg-tip{
            flex-direction: column;
            align-items: flex-start;

            .label{
              display: flex;
              text-align: left;
              margin-bottom: 12px;

              .key{
                min-width: 60px;
              }
              .value{
                flex: 1;
                min-width: 0;
                word-break: break-all;
              }
            }
            .line{
              display: none;
            }
          }
        }
        .button-container {
          flex-direction: column;
          margin-top: 0;
        }
        .lists {
          height: auto;
          width: 100%;
          flex-direction: column;
          .list-container{
            width: 100%;
            margin-bottom: 24px;
            .dids{
              margin-top: 8px;
              height: 120px;
            }
          }
        }
      }
      .mydomain, .derivatives, .batch {
        width: 100%;
        min-height: auto;
        margin-bottom: 80px;
      }
    }
    .anticon-right{
      width: auto;
      transform: rotate(90deg);
      margin: 8px 0;
    }
  }

}
</style>
